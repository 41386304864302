import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import SearchTableCreateInvoice from './components/SearchTableCreateInvoice';
import SearchTableListInvoice from './components/SearchTableListInvoice';
import { useProjectStore } from '../../../contexts/globalStoreProjects';

import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useInvoicesStore } from '../../../contexts/globalStoreInvoices';
import { useFakturaUsersStore } from 'contexts/globalStoreFakturaUsers';

export default function Invoices() {
  const fetchProjects = useProjectStore((state) => state.fetchProjects);
  const fetchUsers = useFakturaUsersStore((state) => state.fetchUsers);
  const fetchTasks = useProjectStore((state) => state.fetchTasks);

  const set = useInvoicesStore((state) => state.set);

  // We need it to update the second tab by creating a new invoice
  const [newInvoiceCreatedTrigger, setNewInvoiceCreatedTrigger] = useState(false);

  useEffect(
    () => {
      let isCurrent = true;
      const fetchData = async () => {
        const res1 = fetchUsers();
        const res2 = fetchProjects(null, null, null, null, true);
        const res3 = fetchTasks();

        await Promise.all([res1, res2, res3]).then(() => {
          set((state: any) => {
            state.isInitDone = true;
          });
        });
      };
      if (isCurrent) {
        fetchData();
      }

      return () => {
        isCurrent = false;
      };
    }, [fetchProjects, fetchTasks, fetchUsers, set],
  );

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      <Tabs variant="enclosed">
        <TabList>
          <Tab data-test-id="invoices-create-tab"> {t('create', { ns: ['labels'] })}</Tab>
          <Tab data-test-id="invoices-list-tab"> {t('list', { ns: ['labels'] })}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Card px="0px">
              <SearchTableCreateInvoice setNewInvoiceCreatedTrigger={setNewInvoiceCreatedTrigger} />
            </Card>
          </TabPanel>
          <TabPanel>
            <Card px="0px">
              <SearchTableListInvoice
                newInvoiceCreatedTrigger={newInvoiceCreatedTrigger}
                setNewInvoiceCreatedTrigger={setNewInvoiceCreatedTrigger} />
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
