import {
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useToast,
  FormLabel,
  Select,
  Container,
  Checkbox,
  InputGroup,
  Input,
  InputRightAddon,
  Button,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBookingStore } from '../../../../contexts/globalStoreTimeBookings';
import Card from '../../../../components/card/Card';
import { MdChevronLeft } from 'react-icons/md';
import { PostosProject, ProjectTaskWithName, ProjectUser, useProjectStore } from 'contexts/globalStoreProjects';
import useUserDataRolesStore from 'contexts/authStore';
import { User, useFakturaUsersStore } from 'contexts/globalStoreFakturaUsers';
import {
  convertMinutesToHHmmForInput,
  getDateTillFromDateAndDuration,
  getDefaultDateFromSimpleDateString,
  getDurationFromDates,
  getSimpleDateForInputFromFullDateString
} from 'utils/dateHelpers';
import { ProjectUserRoles } from 'enums/userRoles';
import useLoaderStore from 'contexts/globalStore';
import useFormErrorsStore from 'contexts/formErrorsStore';

interface YupTestContextExtended {
  dateTill?: Date;
  dateFrom?: Date;
}

const validationSchema = yup.object().shape({
  projectId: yup
    .number()
    .required('projectRequired')
    .min(1, 'projectRequired'),
  userId: yup.number().typeError("typeErrorUser").required('typeErrorUser').min(1, 'userRequired'),
  taskId: yup.number().required('taskRequired').min(1, 'taskRequired'),
  status: yup.string().required('statusRequired'),
  description: yup
    .string()
    .required('descriptionRequired')
    .max(10240, 'descriptionLength'),
  internalNote: yup
    .string()
    .nullable()
    .max(10240, 'descriptionLength'),
  dateFrom: yup.date().typeError("dateRequired").required('dateRequired').test({
    name: 'isDateFromLessThanDateTill',
    message: 'First date should be less than second date',
    test: function (value, context) {
      const { dateTill } = context as yup.TestContext & YupTestContextExtended;
      if (!dateTill) return true;
      return value < dateTill
    },
  }),
  dateTill: yup.date().typeError("dateRequired").required('dateRequired').test({
    name: 'isDateFromLessThanDateTill',
    message: 'First date should be less than second date',
    test: function (value, context) {
      const { dateFrom } = context as yup.TestContext & YupTestContextExtended;
      if (!dateFrom) return true;
      return value > dateFrom
    },
  }),
  duration: yup
    .number()
    .required('durationRequired')
    .max(4096, 'durationMax')
    .min(1, 'durationMin'),
});

export default function AddEditBooking() {
  const toast = useToast();
  const navigate = useNavigate();
  let { id } = useParams();
  const location = useLocation();

  const fetchProjects = useProjectStore((state) => state.fetchProjects);
  const projects = useProjectStore((state) => state.projects);
  const allUserProjectsIds = useUserDataRolesStore((state) => state.allUserProjectsIds);
  const userProjectsIdsByRole = useUserDataRolesStore((state) => state.userProjectsIdsByRole);

  const userProjects = useMemo(() => {
    return projects.filter((project: PostosProject) => allUserProjectsIds.includes(project.id));
  }, [allUserProjectsIds, projects]);

  const fetchSingleBooking = useBookingStore(
    (state) => state.fetchSingleBooking,
  );

  const fetchUserData = useUserDataRolesStore((state) => state.fakturaFetchUserData);
  const userData = useUserDataRolesStore((state) => state.userData);

  const singleBookingData = useBookingStore((state) => state.singleBookingData);

  const users = useFakturaUsersStore((state) => state.users);
  const project = useProjectStore((state) => state.project);
  const projectTasks = useProjectStore((state) => state.projectTasks);
  const allTasks = useProjectStore((state) => state.allTasks);
  const allProjectUsersOfTheUser = useUserDataRolesStore((state) => state.allProjectUsersOfTheUser);

  const fetchSingleProject = useProjectStore((state) => state.fetchSingleProject);
  const fetchUsers = useFakturaUsersStore((state) => state.fetchUsers);
  const fetchProjectTasks = useProjectStore((state) => state.fetchProjectTasks);
  const fetchProjectUsers = useProjectStore((state) => state.fetchProjectUsers);
  const fetchAllTasks = useProjectStore((state) => state.fetchTasks);

  const statusesAdmin = useBookingStore((state) => state.statusesAdmin);
  const statusesUser = useBookingStore((state) => state.statusesUser);

  const resetData = useBookingStore((state) => state.reset);
  const submitData = useBookingStore((state) => state.addOrEditBookingCall);
  const setData = useBookingStore((state) => state.set);

  const resetErrors = useFormErrorsStore((state) => state.resetErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const setShowErrors = useFormErrorsStore((state) => state.setShowErrors);
  const errors = useFormErrorsStore((state) => state.errors);
  const setErrors = useFormErrorsStore((state) => state.setErrors);

  const [enableSubmit, setEnableSubmit] = useState(false);

  const [onlyChangesAllowedInCommitState, setOnlyChangesAllowedInCommitState] = useState(false);

  const [durationHHmm, setDurationHHmm] = useState('');

  const [eachFieldStateValidation, setEachFieldStateValidation] = useState({
    projectId: { valid: true, error: '' },
    userId: { valid: true, error: '' },
    taskId: { valid: true, error: '' },
    status: { valid: true, error: '' },
    description: { valid: true, error: '' },
    internalNote: { valid: true, error: '' },
    dateFrom: { valid: true, error: '' },
    dateTill: { valid: true, error: '' },
    duration: { valid: true, error: '' },
  });

  const handleChange = useCallback(
    (field: any, value: any) => {
      setData((state: any) => {
        state.singleBookingData[field] = value;
      });
    }, [setData]
  )

  const validateSingleField = useCallback(async (path: string, input: any) => {
    // `reach()` pulls out a child schema so we can test a single path
    const field: any = yup.reach(validationSchema, path);

    try {
      await field.validate(input);
      setEachFieldStateValidation({
        ...eachFieldStateValidation,
        [path]: { valid: true, error: '' },
      });
    } catch (e: any) {
      setEachFieldStateValidation({
        ...eachFieldStateValidation,
        [path]: { valid: false, error: e.errors.join(', ') },
      });
      setErrors([...errors,
      e.errors.map((e: any) => t(e, { ns: ['hints'] })).join(', ')
      ]);
      return e.errors;
    }
  }, [eachFieldStateValidation, errors, setErrors]);

  const loadingState = useLoaderStore((state) => state.isLoading);
  const [isSubmittingInProcess, setIsSubmittingInProcess] = useState(false);
  const isDuplicate = useMemo(() => location.pathname.includes('duplicate'), [location]);
  const isUserProjectAdmin = useMemo(() =>
    userProjectsIdsByRole.techAdmin.includes(singleBookingData.projectId),
    [userProjectsIdsByRole, singleBookingData]);

  const [projectUsersAvailableForBookings, setProjectUsersAvailableForBookings] = useState<ProjectUser[]>([]);

  const [userIdInBooking, setUserIdInBooking] = useState(null);

  const isUserAvailableForBookings = useCallback(
    (projectUser: ProjectUser, projectId = singleBookingData.projectId): boolean =>
      projectUser.projectId === projectId && projectUser.role === ProjectUserRoles.USER,
    [singleBookingData.projectId]);

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      if (id) {
        resetData();

        fetchSingleBooking(Number(id), isDuplicate)
          .then((res) => {
            if (!res || res.type !== 'success') {
              toast({
                title: t('error', { ns: ['labels'] }),
                description: res.message,
                status: res.type,
              });
            } else {
              const loadedSingleBooking = res.data;
              const isUserProjectAdmin = userProjectsIdsByRole.techAdmin.includes(loadedSingleBooking.projectId);

              if (loadedSingleBooking.userId) {
                const isUserBookingUser = allProjectUsersOfTheUser
                  .some(projectUser => projectUser.id === loadedSingleBooking.userId);

                if (!isDuplicate && (
                  (loadedSingleBooking.status !== 'COMMIT' && loadedSingleBooking.status !== 'DRAFT')
                  || (!isUserProjectAdmin && !isUserBookingUser)
                )) {
                  navigate('/faktura/bookings');
                }

                if (!isDuplicate && loadedSingleBooking.status !== 'DRAFT') {
                  fetchProjectTasks(Number(loadedSingleBooking.projectId));
                  fetchProjectUsers(Number(loadedSingleBooking.projectId)).then((res) => {
                    setProjectUsersAvailableForBookings(
                      res.filter(pu => isUserAvailableForBookings(pu, loadedSingleBooking.projectId))
                    );

                    setUserIdInBooking(res?.find(
                      pu => pu.id === loadedSingleBooking.userId
                        && pu.projectId === loadedSingleBooking.projectId
                        && pu.role === ProjectUserRoles.USER
                    )?.userId || null);
                  });
                  setOnlyChangesAllowedInCommitState(true);
                } else {
                  setOnlyChangesAllowedInCommitState(false);
                }
              }
            }
          })
          .catch((err) => {
            toast({
              title: t('error', { ns: ['labels'] }),
              description: err.message,
              status: err.type,
            });
          });
      } else {
        resetData();
        setEnableSubmit(false);
      }
      fetchProjects(null, null, null, null, true);
    }

    return () => {
      isCurrent = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSingleBooking,
    fetchProjects,
    id,
    loadingState,
    navigate,
    resetData,
    toast,
  ]);

  useEffect(
    () => {
      if (singleBookingData.projectId) {
        fetchSingleProject(Number(singleBookingData.projectId));
        fetchUsers();
        fetchUserData();
        fetchAllTasks();
      }
    },
    [fetchAllTasks,
      fetchProjectTasks,
      fetchSingleProject,
      fetchUserData,
      fetchUsers,
      singleBookingData.projectId],
  );

  //TODO DRY ProjectTasks
  const getTaskName = useCallback((taskId: number) => {
    const found = allTasks.find((taskFromAll) => taskFromAll.id === taskId)
    return found?.name ? found.name : ''
  }, [allTasks]);

  const validateFormData = useCallback(async (formData: any) => {
    try {
      await validationSchema.validate(formData, {
        abortEarly: false,
      });

    } catch (error: any) {
      // This is to show errors for fields after submit
      // Even if they were not touched
      //TODO DRY with Invoice tab
      const newEachFieldStateValidation: any = { ...eachFieldStateValidation };
      if (error.inner) {
        for (const e of error.inner) {
          const fieldErrors = await validateSingleField(e.path, e.value);

          if (fieldErrors) {
            newEachFieldStateValidation[e.path] = { valid: false, error: fieldErrors.join(', ') };
          }
        }
      }
      setEachFieldStateValidation(newEachFieldStateValidation);
    }
    // Will work incorrectly if you add validateSingleField and eachFieldStateValidation as dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validateFormData(singleBookingData)
  }, [singleBookingData, validateFormData]);

  useEffect(() => {
    const validation = async () => {
      try {
        await validationSchema.validate(singleBookingData, {
          abortEarly: false,
          context: singleBookingData,
        });
        setEnableSubmit(true);
      } catch (err) {
        setEnableSubmit(false);
      }
    };

    validation();
  }, [singleBookingData]);

  const matchDateTillToDuration = useCallback((
    dateFrom: string,
    duration: number,
  ) => {
    if (!dateFrom) {
      if (singleBookingData?.dateFrom) {
        dateFrom = singleBookingData.dateFrom
      } else {
        return;
      }
    }
    if (!duration) {
      if (singleBookingData?.duration) {
        duration = singleBookingData.duration
      } else {
        return;
      }
    }

    handleChange(
      'dateTill',
      getDateTillFromDateAndDuration(
        singleBookingData.isEntryByTimeframe ? dateFrom :
          getDefaultDateFromSimpleDateString(dateFrom),
        duration
      ),
    );
  }, [handleChange,
    singleBookingData.dateFrom,
    singleBookingData.duration,
    singleBookingData.isEntryByTimeframe])

  const matchDurationToDates = useCallback((
    dateFrom: string,
    dateTill: string,
  ) => {
    if (!dateFrom) {
      if (singleBookingData?.dateFrom) {
        dateFrom = singleBookingData.dateFrom
      } else {
        return;
      }
    }
    if (!dateTill) {
      if (singleBookingData?.dateTill) {
        dateTill = singleBookingData.dateTill
      } else {
        return;
      }
    }

    setIsDurationSetFromRest(false)
    handleChange(
      'duration',
      getDurationFromDates(dateFrom, dateTill),
    );
  }, [handleChange, singleBookingData.dateFrom, singleBookingData.dateTill])

  const handleChangeIsEntryByTimeframe = useCallback((isChecked: boolean) => {
    if (isChecked && singleBookingData.dateFrom) {
      // If we change to Timeframe, update dateFrom to the new format with time
      handleChange(
        'dateFrom',
        getDefaultDateFromSimpleDateString(singleBookingData.dateFrom),
      );
    } else if (!isChecked && singleBookingData.dateFrom) {
      // If we change to Duration, update dateFrom to the new format without time
      handleChange(
        'dateFrom',
        getSimpleDateForInputFromFullDateString(singleBookingData.dateFrom),
      )
    }
    handleChange('isEntryByTimeframe', isChecked)
  }, [handleChange, singleBookingData.dateFrom])

  // We have to do useEffect below only when we get duration in minutes from store
  const [isDurationSetFromRest, setIsDurationSetFromRest] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  useEffect(() => {
    // If booking is just created, we don't do anything on start
    if ((id && !isDuplicate) || !isFirstLoad) {
      // Set duration in minutes to HH:mm for input
      if (!isDurationSetFromRest && singleBookingData.duration) {
        const newHHmmDuration = convertMinutesToHHmmForInput(singleBookingData.duration);

        setDurationHHmm(newHHmmDuration);
        setIsDurationSetFromRest(true);
      }
    }
    setIsFirstLoad(false);
  }, [id, isDuplicate, isDurationSetFromRest, isFirstLoad, singleBookingData.duration]);

  useEffect(
    // Convert duration from HH:mm to minutes and save to the store
    () => {
      if (durationHHmm) {
        const [hours, minutes] = durationHHmm.split(':');

        let localDuration = Number(hours) * 60 + Number(minutes);

        if (isNaN(localDuration)) {
          localDuration = 0;
        }

        handleChange('duration', localDuration);
        validateSingleField('duration', localDuration);
        if (localDuration && singleBookingData.dateFrom) {
          matchDateTillToDuration(singleBookingData.dateFrom, localDuration);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [durationHHmm])

  useEffect(() => {
    if (!id && !project.costCenterNonBillable) {
      handleChange('isBillable', true);
    }
  }, [project.costCenterNonBillable, handleChange, id]);

  const optionForProjectUser = useCallback(() => {
    const projectUserAuthorForSelection = allProjectUsersOfTheUser.find(
      (projectUser) =>
        projectUser.project_id === project.id && projectUser.role === ProjectUserRoles.USER,
    )

    const userProjectId = projectUsersAvailableForBookings.find(
      (projectUser: ProjectUser) => projectUser.id === projectUserAuthorForSelection?.id,
    )?.id

    if (userProjectId) {
      const isUserProjectAdmin = userProjectsIdsByRole.techAdmin.includes(project.id);
      if (!isUserProjectAdmin) {
        handleChange('userId', userProjectId);
      }
      return (
        <option key={userProjectId} value={userProjectId}>
          {userData?.name ? userData.name : ''} ({userData?.email ? userData.email : ''})
        </option>
      );
    } else {
      return null;
    }
  }, [allProjectUsersOfTheUser, handleChange, project.id, projectUsersAvailableForBookings, userData, userProjectsIdsByRole.techAdmin]);

  useEffect(() => {
    return () => { resetErrors() };
  }, [resetErrors]);

  const handleDataSubmit = () => {
    setIsSubmittingInProcess(true);
    if (!isProjectAvailableForChange) {
      toast({
        title: t('error', { ns: ['labels'] }),
        description: t('cantChangeBookingToProject', { ns: ['labels'] }),
        duration: null,
        status: 'error',
      });
      setIsSubmittingInProcess(false);
      return;
    }
    if (!enableSubmit) {
      toast({
        title: t('error', { ns: ['labels'] }),
        description: errors.map((error, index) => (<Text key={index}>
          {error}
        </Text>)),
        duration: null,
        status: 'error',
      });
      setShowErrors(true);
      setIsSubmittingInProcess(false);
      return;
    } else {
      setShowErrors(false);
    }
    submitData(userData.id)
      .then((res) => {
        if (res) {
          toast({
            title:
              res.type === 'success'
                ? t('success', { ns: ['labels'] })
                : t('error', { ns: ['labels'] }),
            description: res.message,
            status: res.type,
          });
          if ((!id || isDuplicate) && res.type === 'success')
            navigate(res.navigateId ? `/faktura/bookings/edit/${res.navigateId}` : `/faktura/bookings`);
          if (res.warningMessage) {
            toast({
              title: t('warning', { ns: ['labels'] }),
              description: res.warningMessage,
              status: 'warning',
            });
          }
        }
        setIsSubmittingInProcess(false);
      })
      .catch((err) => {
        toast({
          title: t('error', { ns: ['labels'] }),
          description: err.message,
          status: err.type,
        });
        setIsSubmittingInProcess(false);
      });
  };
  const textColor = useColorModeValue('black', 'white');

  const onProjectChange = useCallback(async (event: any) => {
    const newProjectId = Number(event.target.value)
    const newProjectUsers = await fetchProjectUsers(newProjectId);
    await fetchProjectTasks(newProjectId);

    const newProjectUsersAvailableForBookings =
      newProjectUsers?.filter(pu => isUserAvailableForBookings(pu, newProjectId)) || []

    setProjectUsersAvailableForBookings(newProjectUsersAvailableForBookings)

    if (onlyChangesAllowedInCommitState) {
      const newProjectUserId = newProjectUsersAvailableForBookings?.find(
        (user) => user.userId === userIdInBooking
      )?.id || null

      newProjectUserId && handleChange('userId', newProjectUserId);
      // We don't reset the below it if we can only change the project
    } else {
      handleChange('userId', null);
      handleChange('taskId', null);
    }
    handleChange('projectId', newProjectId);
    validateSingleField('projectId', newProjectId);
  }, [fetchProjectUsers, fetchProjectTasks, isUserAvailableForBookings, onlyChangesAllowedInCommitState, handleChange, validateSingleField, userIdInBooking]);

  // If we change a project in committed state, 
  // but there is no such task/user in the project, we can't change it
  const isProjectAvailableForChangeByTask = useMemo(() => !onlyChangesAllowedInCommitState ||
    projectTasks?.map(t => t.taskId).includes(singleBookingData?.taskId),
    [onlyChangesAllowedInCommitState, projectTasks, singleBookingData?.taskId]);

  const isProjectAvailableForChangeByUser = useMemo(() => !onlyChangesAllowedInCommitState ||
    projectUsersAvailableForBookings?.map(pu => pu.userId).includes(userIdInBooking),
    [onlyChangesAllowedInCommitState, projectUsersAvailableForBookings, userIdInBooking]);

  const isProjectAvailableForChange = useMemo(() =>
    isProjectAvailableForChangeByTask && isProjectAvailableForChangeByUser,
    [isProjectAvailableForChangeByTask, isProjectAvailableForChangeByUser]);

  return (
    <Flex
      direction="column"
      align="center"
      pt={{ sm: '125px', lg: '75px' }}
      position="relative"
    >
      <Container maxW="960px">
        <Card p={8} w="100%">
          <Stack direction="row" w="100%" justifyContent="space-between" mb={4}>
            <Button
              variant="outline"
              fontSize="sm"
              borderRadius="12px"
              w={{ base: '128px', md: '148px' }}
              h="46px"
              data-test-id="booking-back-to-list-button"
              onClick={() => navigate('/faktura/bookings')}
            >
              <Icon as={MdChevronLeft} mr={1} />
              {t('back', { ns: ['actions'] })}
            </Button>
            <Button
              variant="darkBrand"
              fontSize="sm"
              borderRadius="12px"
              w={{ base: '128px', md: '148px' }}
              h="46px"
              data-test-id="booking-submit-button"
              isLoading={isSubmittingInProcess}
              disabled={!enableSubmit || !isProjectAvailableForChange}
              onClick={handleDataSubmit}
            >
              {t('submit', { ns: ['labels'] })}
            </Button>
          </Stack>
          <Flex direction="column" w="100%">
            <SimpleGrid columns={{ base: 1 }} gap={4}>
              <Stack direction="column" gap={4}>
                <Flex direction="column">
                  <FormLabel
                    ms="10px"
                    htmlFor="projectId"
                    fontSize="sm"
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    {t('project', { ns: ['labels'] }) + '*'}
                  </FormLabel>
                  <Select
                    variant="main"
                    fontSize="sm"
                    id="projectId"
                    color={textColor}
                    h="44px"
                    maxH="44px"
                    placeholder={t('select', { ns: ['labels'] })}
                    value={singleBookingData?.projectId || ''}
                    required
                    data-test-id="booking-project-select"
                    onChange={onProjectChange}
                  >
                    {[...userProjects]?.sort(
                      (a, b) => a.title.localeCompare(b.title)
                    )
                      .map((project: any) => {
                        return (
                          <option key={project.id} value={project.id}>
                            {project.title}
                          </option>
                        );
                      })}
                  </Select>
                  <Text data-test-id="booking-project-select-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && eachFieldStateValidation?.['projectId']?.error
                      ? t(eachFieldStateValidation['projectId'].error, { ns: ['hints'] })
                      : null}
                    &nbsp;
                  </Text>
                </Flex>
              </Stack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap={4}>
              <Stack direction="column" gap={4}>
                <Flex direction="column">
                  <FormLabel
                    ms="10px"
                    htmlFor="userId"
                    fontSize="sm"
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    {t('user', { ns: ['labels'] }) + '*'}
                  </FormLabel>
                  <Select
                    variant="main"
                    fontSize="sm"
                    id="userId"
                    color={textColor}
                    h="44px"
                    maxH="44px"
                    data-test-id="booking-user-select"
                    placeholder={
                      !isProjectAvailableForChangeByUser ?
                        t('noSuchUserInProject', { ns: ['labels'] }) :
                        !projectUsersAvailableForBookings?.length
                          ? t('noUsersInProject', { ns: ['labels'] })
                          : isUserProjectAdmin
                            ? t('select', { ns: ['labels'] })
                            : null}
                    value={Number(singleBookingData?.userId) || ''}
                    required
                    onChange={(event: any) => {
                      handleChange('userId', Number(event.target.value));
                      validateSingleField('userId', Number(event.target.value));
                    }}
                    disabled={
                      !projectUsersAvailableForBookings?.length ||
                      !isUserProjectAdmin ||
                      onlyChangesAllowedInCommitState
                    }
                  >
                    {isUserProjectAdmin
                      ? users.filter(
                        (user: User) =>
                          // Ids of this project users
                          projectUsersAvailableForBookings.map((projectUser: ProjectUser) => projectUser.userId)
                            // Includes the user from array by id
                            .includes(user.userId))
                        .map((user: User) => {
                          // We need to pass user project id in project, 
                          // as it is the relation to project_users entity
                          const userProjectId = projectUsersAvailableForBookings.find(
                            (projectUser: ProjectUser) =>
                              user.userId === projectUser?.userId,
                          )?.id

                          return (<option key={userProjectId} value={userProjectId}>
                            {user.name} ({user.email}){user.status === 'ACTIVE' ? '' : ` (${t('inactive', { ns: ['labels'] })})`}
                          </option>)
                        })
                      : projectUsersAvailableForBookings?.length
                        ? optionForProjectUser() : []}
                  </Select>
                  <Text data-test-id="booking-user-select-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && eachFieldStateValidation?.['userId']?.error
                      ? t(eachFieldStateValidation['userId'].error, { ns: ['hints'] })
                      : null}
                    &nbsp;
                  </Text>
                </Flex>
              </Stack>
              <Stack direction="column" gap={4}>
                <Flex direction="column">
                  <FormLabel
                    ms="10px"
                    htmlFor="taskId"
                    fontSize="sm"
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    {t('task', { ns: ['labels'] }) + '*'}
                  </FormLabel>
                  <Select
                    variant="main"
                    fontSize="sm"
                    id="taskId"
                    color={textColor}
                    h="44px"
                    maxH="44px"
                    data-test-id="booking-task-select"
                    placeholder={
                      !isProjectAvailableForChangeByTask ?
                        t('noSuchTaskInProject', { ns: ['labels'] }) :
                        projectTasks?.length ?
                          t('select', { ns: ['labels'] }) :
                          t('noTasksInProject', { ns: ['labels'] })}
                    value={singleBookingData?.taskId || ''}
                    required
                    disabled={!projectTasks?.length || onlyChangesAllowedInCommitState}
                    onChange={(event: any) => {
                      handleChange('taskId', Number(event.target.value));
                      validateSingleField('taskId', Number(event.target.value));
                    }}
                  >
                    {projectTasks?.length && projectTasks.every(
                      (task: ProjectTaskWithName) => task.name
                    ) && [...projectTasks].sort(
                      (a, b) => a.name.localeCompare(b.name)
                    ).map((task: any) => {
                      return (
                        <option key={task.id} value={task.taskId}>
                          {getTaskName(task.taskId)}
                        </option>
                      );

                    })}
                  </Select>
                  <Text data-test-id="booking-task-select-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && eachFieldStateValidation?.['taskId']?.error
                      ? t(eachFieldStateValidation['taskId'].error, { ns: ['hints'] })
                      : null}
                    &nbsp;
                  </Text>
                </Flex>
              </Stack>
              <Stack direction="column" gap={4}>
                <Flex direction="column">
                  <FormLabel
                    ms="10px"
                    htmlFor="status"
                    fontSize="sm"
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    {t('status', { ns: ['labels'] }) + '*'}
                  </FormLabel>
                  <Select
                    variant="main"
                    fontSize="sm"
                    id="status"
                    color={textColor}
                    h="44px"
                    maxH="44px"
                    placeholder={t('select', { ns: ['labels'] })}
                    value={singleBookingData?.status || ''}
                    required
                    data-test-id="booking-status-select"
                    onChange={(event: any) => {
                      handleChange('status', event.target.value);
                      validateSingleField('status', event.target.value);
                    }}
                  >
                    {isUserProjectAdmin
                      ? statusesAdmin?.map((status: any) => {
                        return (
                          <option key={status} value={status}>
                            {t(status, { ns: ['status'] })}
                          </option>
                        );
                      })
                      : statusesUser?.map((status: any) => {
                        return (
                          <option key={status} value={status}>
                            {t(status, { ns: ['status'] })}
                          </option>
                        );
                      })}
                  </Select>
                  <Text data-test-id="booking-status-select-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && eachFieldStateValidation?.['status']?.error
                      ? t(eachFieldStateValidation['status'].error, { ns: ['hints'] })
                      : null}
                    &nbsp;
                  </Text>
                </Flex>
              </Stack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1 }} gap={4}>
              <Stack direction="column" gap={4}>
                <Flex direction="column">
                  <TextField
                    variant="main"
                    h="146px"
                    mb="0px"
                    id="description"
                    color={textColor}
                    placeholder={t('description', { ns: ['labels'] })}
                    value={singleBookingData?.description || ''}
                    label={t('description', { ns: ['labels'] }) + '*'}
                    data-test-id="booking-description-field"
                    disabled={onlyChangesAllowedInCommitState}
                    onChange={(event: any) => {
                      handleChange('description', event.target.value);
                      validateSingleField('description', event.target.value);
                    }}
                  />
                  <Text data-test-id="booking-description-field-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && eachFieldStateValidation?.['description']?.error
                      ? t(eachFieldStateValidation['description'].error, { ns: ['hints'] })
                      : null}
                    &nbsp;
                  </Text>
                </Flex>
              </Stack>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1 }} gap={4}>
              <Stack direction="column" gap={4}>
                <Flex direction="column">
                  <TextField
                    variant="main"
                    h="146px"
                    mb="0px"
                    id="internalNote"
                    color={textColor}
                    placeholder={t('internalNote', { ns: ['labels'] })}
                    value={singleBookingData?.internalNote || ''}
                    label={t('internalNote', { ns: ['labels'] })}
                    data-test-id="booking-internal-note-field"
                    disabled={onlyChangesAllowedInCommitState}
                    onChange={(event: any) => {
                      handleChange('internalNote', event.target.value);
                      validateSingleField('internalNote', event.target.value);
                    }}
                  />
                  <Text data-test-id="booking-internal-note-field-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && eachFieldStateValidation?.['internalNote']?.error
                      ? t(eachFieldStateValidation['internalNote'].error, { ns: ['hints'] })
                      : null}
                    &nbsp;
                  </Text>
                </Flex>
              </Stack>
            </SimpleGrid>
            {project?.costCenterNonBillable ? (
              <SimpleGrid columns={{ base: 1 }} gap={0}>
                <Stack direction="column" gap={0}>
                  <Checkbox
                    mb={4}
                    isChecked={singleBookingData?.isBillable}
                    data-test-id="booking-is-billable-checkbox"
                    disabled={onlyChangesAllowedInCommitState}
                    onChange={(event: any) =>
                      handleChange('isBillable', event.target.checked)
                    }
                  >
                    {`${t("isBillable", { ns: ["labels"] })}?`}
                  </Checkbox>
                </Stack>
              </SimpleGrid>
            ) : null}
            <SimpleGrid columns={{ base: 1 }} gap={0}>
              <Stack direction="column" gap={0}>
                <Checkbox
                  mb={4}
                  isChecked={singleBookingData?.isEntryByTimeframe}
                  data-test-id="booking-is-entry-by-timeframe-checkbox"
                  disabled={onlyChangesAllowedInCommitState}
                  onChange={(event: any) =>
                    handleChangeIsEntryByTimeframe(event.target.checked)
                  }
                >
                  {singleBookingData?.isEntryByTimeframe ? t("entryByTimeframe", { ns: ["labels"] }) : t("amount", { ns: ["labels"] })}
                </Checkbox>
              </Stack>
            </SimpleGrid>
            <SimpleGrid
              columns={{ base: singleBookingData?.isEntryByTimeframe ? 3 : 2 }}
              gap={4}
            >
              <Stack direction="column" gap={4}>
                <Flex direction="column">
                  <InputField
                    mb="0px"
                    id="dateFrom"
                    color={textColor}
                    label={t('dateFrom', { ns: ['labels'] }) + '*'}
                    type={singleBookingData?.isEntryByTimeframe ? "datetime-local" : "date"}
                    value={singleBookingData?.dateFrom}
                    data-test-id="booking-date-from-field"
                    disabled={onlyChangesAllowedInCommitState}
                    onChange={(event: any) => {
                      handleChange('dateFrom', event.target.value);
                      validateSingleField('dateFrom', event.target.value);
                      if (singleBookingData?.dateFrom && !singleBookingData.isEntryByTimeframe) {
                        matchDateTillToDuration(event.target.value, singleBookingData.duration);
                      }
                      if (singleBookingData?.dateFrom && singleBookingData.isEntryByTimeframe) {
                        matchDurationToDates(event.target.value, singleBookingData.dateTill);
                      }
                    }}
                  />
                  <Text data-test-id="booking-date-from-field-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && eachFieldStateValidation?.['dateFrom']?.error
                      ? t(eachFieldStateValidation['dateFrom'].error, { ns: ['hints'] })
                      : null}
                    &nbsp;
                  </Text>
                </Flex>
              </Stack>
              {singleBookingData?.isEntryByTimeframe ? (
                <Stack direction="column" gap={4}>
                  <Flex direction="column">
                    <InputField
                      mb="0px"
                      id="dateTill"
                      color={textColor}
                      label={t('dateTill', { ns: ['labels'] }) + '*'}
                      type="datetime-local"
                      value={singleBookingData?.dateTill || ''}
                      data-test-id="booking-date-till-field"
                      disabled={onlyChangesAllowedInCommitState}
                      onChange={(event: any) => {
                        handleChange('dateTill', event.target.value);
                        matchDurationToDates(singleBookingData.dateFrom, event.target.value);
                      }}
                    />
                    <Text data-test-id="booking-date-till-field-error" m={0} p={0} pl={2} fontSize="sm" color="red.500">
                      {showErrors && eachFieldStateValidation?.['dateTill']?.error
                        ? t(eachFieldStateValidation['dateTill'].error, { ns: ['hints'] })
                        : null}
                      &nbsp;
                    </Text>
                  </Flex>
                </Stack>
              ) : null}
              <Stack direction="column" gap={4}>
                <Flex direction="column" w="100%">
                  <FormLabel
                    ms="10px"
                    htmlFor="status"
                    fontSize="sm"
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    {t('amount', { ns: ['labels'] }) + '*'}
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      readOnly={singleBookingData?.isEntryByTimeframe || !singleBookingData?.dateFrom}
                      color={textColor}
                      value={durationHHmm || ''}
                      placeholder={t('amount', { ns: ['labels'] })}
                      onChange={(event) => setDurationHHmm(event.target.value)}
                      data-test-id="booking-amount-field"
                      disabled={onlyChangesAllowedInCommitState}
                      onFocus={() => {
                        if (!singleBookingData?.dateFrom) {
                          toast({
                            title: t('error', { ns: ['labels'] }),
                            description: t('startDateBeforeDuration', { ns: ['errors'] }),
                          })
                        }
                      }}
                    />
                    <InputRightAddon children="HH:mm" />
                  </InputGroup>
                  {singleBookingData?.isEntryByTimeframe ? (
                    <Text data-test-id="booking-amount-field-error" m={0} p={0} pl={2} fontSize="sm" color="gray.700">
                      {t("readOnly", { ns: ["hints"] })}
                    </Text>
                  ) : (
                    <Text m={0} p={0} pl={2} fontSize="sm" color="red.500">
                      {showErrors && eachFieldStateValidation?.['duration']?.error
                        ? t(eachFieldStateValidation['duration'].error, { ns: ['hints'] })
                        : null}
                      &nbsp;
                    </Text>
                  )}
                </Flex>
              </Stack>
            </SimpleGrid>
          </Flex>
        </Card>
      </Container>
    </Flex>
  );
}
