/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/

=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
import { Api } from 'utils/api';
import auth from 'auth';
import { useTranslation } from 'react-i18next';

// Chakra imports
import {
  Alert,
  AlertIcon,
  // AlertTitle,
  // AlertDescription,
  Box,
  Button,
  // Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  Spinner,
} from '@chakra-ui/react';
// Assets
// import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';

// Custom components
// import { HSeparator } from 'components/separator/Separator';
import CenteredAuth from 'layouts/auth/variants/Centered';
// import { Console } from 'console';

function Login() {
  const { t } = useTranslation(['header', 'hints', 'labels']);
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  // const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  // const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  // const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  // const googleText = useColorModeValue('navy.700', 'white');
  // const googleHover = useColorModeValue(
  //   { bg: 'gray.200' },
  //   { bg: 'whiteAlpha.300' },
  // );
  // const googleActive = useColorModeValue(
  //   { bg: 'secondaryGray.300' },
  //   { bg: 'whiteAlpha.200' },
  // );

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  // Login
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [err, setErr] = useState('');
  const [loadingLogin, setLoadingLogin] = useState(false);

  const api = new Api();

  const submit = () => {
    setErr('');
    setLoadingLogin(true);

    if (formData.email.length === 0) {
      setErr(t('enterEmail', { ns: ['hints'] }));
      setLoadingLogin(false);
      return;
    }

    if (formData.password.length === 0) {
      setErr(t('enterPassword', { ns: ['hints'] }));
      setLoadingLogin(false);
      return;
    }
    const lowercasedEmail = formData.email.toLowerCase().trim();
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: lowercasedEmail,
    }));

    api.loginByPassword
      .loginByPassword(formData)
      .then((res: any) => {
        if (res.data.status === 'SUCCESS') {
          localStorage.userDataPostos = JSON.stringify(res.data.data.body);
          auth.userDataPostos.loggedInStatus = true;
          auth.userDataPostos.token = res.data.data.body.token;
          if (
            window.location.search &&
            window.location.search.includes('redirect')
          ) {
            window.open(
              window.location.search.replace('?redirect=', ''),
              '_self',
            );
          } else {
            window.open('/', '_self');
          }
          return;
        }
        setLoadingLogin(false);
        setErr(res.data.statusText);
      })
      .catch((err: any) => {
        console.log(err);
        setErr(t('loginFailed', { ns: ['hints'] }));
        setLoadingLogin(false);
      });
  };

  return (
    <CenteredAuth
      cardTop={{ base: '140px', md: '14vh' }}
      cardBottom={{ base: '50px', lg: 'auto' }}
      mx="0px"
    >
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        justifyContent="center"
        px={{ base: '20px', md: '0px' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            {t('login', { ns: ['header'] })}
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            {t('loginHint', { ns: ['hints'] })}
          </Text>
        </Box>
        {/* Error Message */}
        {err && (
          <Alert status="error" variant="solid" mb="36px" data-cy={err}>
            <AlertIcon />
            {err}
          </Alert>
        )}
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          {/* <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button> */}
          {/* <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex> */}
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              {t('email', { ns: ['labels'] })}
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: '0px', md: '0px' }}
              type="email"
              placeholder="mail@example.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              onKeyUp={(e) => e.key === 'Enter' && submit()}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              {t('password', { ns: ['labels'] })}
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                ms={{ base: '0px', md: '4px' }}
                placeholder={t('passwordPlaceholder', { ns: ['hints'] })}
                mb="24px"
                size="lg"
                type={show ? 'text' : 'password'}
                variant="auth"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                onKeyUp={(e) => e.key === 'Enter' && submit()}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {/* Checkbox & Forgot Password feature - waiting on backend to be done*/}
            {/* <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  {t('stayLoggedIn', { ns: ['labels'] })}
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                  whiteSpace="nowrap"
                >
                  {t('forgotPassword', { ns: ['labels'] })}
                </Text>
              </NavLink>
            </Flex> */}
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={submit}
              disabled={loadingLogin}
            >
              {loadingLogin ? (
                <>
                  <Spinner size="sm" mr="2" />
                  {t('loading', { ns: ['labels'] })}
                </>
              ) : (
                t('signIn', { ns: ['labels'] })
              )}
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            {/* <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text> */}
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default Login;
