// chakra imports
import {
  Icon,
} from '@chakra-ui/react';
// Assets
import useUserDataRolesStore from "../../../contexts/authStore";
import CustomMenuLink from './CustomMenuLink';
import {
  MdAccessTime,
  MdBarChart,
  MdDomain,
  MdHome,
  MdMediation,
  MdOutlineFactCheck,
  MdOutlinePaid
} from 'react-icons/md';
import { useMemo } from 'react';
import {
  checkIfUserProjectCreator,
  checkIfUserFakturaAdmin,
  checkIfUserCompanyCreator,
  checkIfUserCostCenterCreator,
} from 'utils/roleHelpers';

export function SidebarLinks(props: {
  hovered: boolean;
  mini?: boolean;
}) {
  const { hovered, mini } = props;

  const userRoles = useUserDataRolesStore((state) => state.userRoles);
  const hasUserRoleInAnyProject = useUserDataRolesStore((state) => state.hasUserRoleInAnyProject);

  const isUserFakturaAdmin = useMemo(() => checkIfUserFakturaAdmin(userRoles), [userRoles]);
  const isUserProjectCreator = useMemo(() => checkIfUserProjectCreator(userRoles), [userRoles]);
  const isUserCompanyCreator = useMemo(() => checkIfUserCompanyCreator(userRoles), [userRoles]);
  const isUserCostCenterCreator = useMemo(() => checkIfUserCostCenterCreator(userRoles), [userRoles]);

  return (
    <>
      <CustomMenuLink
        title="home"
        path="/"
        mini={mini}
        hovered={hovered}
        icon={<Icon as={MdHome} width="20px" height="20px" color="inherit" />}
        data-test-id="main-menu-home-link"
      />

      {isUserFakturaAdmin
        || isUserProjectCreator
        || hasUserRoleInAnyProject.techAdmin
        || hasUserRoleInAnyProject.finAdmin ?
        <CustomMenuLink
          title="projects"
          path="/projects"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdMediation} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-projects-link"
        />
        : null}
      {isUserCompanyCreator || hasUserRoleInAnyProject.techAdmin || hasUserRoleInAnyProject.finAdmin ?
        <CustomMenuLink
          title="companies"
          path="/companies"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdDomain} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-companies-link"
        />
        : null}
      {isUserCostCenterCreator || hasUserRoleInAnyProject.techAdmin || hasUserRoleInAnyProject.finAdmin ?
        <CustomMenuLink
          title="cost-centers"
          path="/cost-centers"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdOutlineFactCheck} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-cost-centers-link"
        />
        : null
      }

      {hasUserRoleInAnyProject.user
        || hasUserRoleInAnyProject.techAdmin
        || hasUserRoleInAnyProject.finAdmin ? (
        <CustomMenuLink
          title="bookings"
          path="/faktura/bookings"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdAccessTime} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-bookings-link"
        />) : null}

      {hasUserRoleInAnyProject.techAdmin || hasUserRoleInAnyProject.finAdmin ? (
        <CustomMenuLink
          title="reports"
          path="/faktura/reports"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdBarChart} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-reports-link"
        />
      ) : null}

      {hasUserRoleInAnyProject.finAdmin ? (
        <CustomMenuLink
          title="invoices"
          path="/faktura/invoices"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdOutlinePaid} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-invoices-link"
        />
      ) : null}
    </>
  )
}

export default SidebarLinks;
