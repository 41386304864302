import { Api, PostosProjectsUsersBODYValidationRule } from 'utils/postos-api';
import { create } from 'zustand';
import { produce } from 'immer';
import { ProjectUserRoles } from 'enums/userRoles';

const api = new Api();

export interface ProjectUserFromApi extends PostosProjectsUsersBODYValidationRule {
  id?: number;
}

export interface UserDataStore {
  userRoles: UserRoles[];
  userData: UserData;
  hasUserRoleInAnyProject: {
    user: boolean,
    techAdmin: boolean,
    finAdmin: boolean,
    invoiceApprover: boolean
  },
  allProjectUsersOfTheUser: ProjectUserFromApi[];
  allUserProjectsIds: number[];
  userProjectsIdsByRole: {
    user: number[],
    techAdmin: number[],
    finAdmin: number[],
    invoiceApprover: number[]
  }
  fakturaFetchUserData: () => Promise<void>;
  checkStaticHash: () => void;
  checkUserRolesInProjects: () => void;
}

export interface UserData {
  createdOn: string;
  email: string;
  id: number;
  modifiedOn: string;
  name: string;
  status: string;
}

export interface UserRoles {
  createdOn: string;
  id: number;
  isAssignableToUser: boolean;
  isSystem: boolean;
  modifiedOn: string;
  name: string;
}

const useUserDataRolesStore = create<UserDataStore>()(
    (set, get) => ({
      userRoles: [],
      userData: {} as UserData,
      allProjectUsersOfTheUser: [] as ProjectUserFromApi[],
      allUserProjectsIds: [],
      userProjectsIdsByRole:  {
        user: [],
        techAdmin: [],
        finAdmin: [],
        invoiceApprover: []
      },
      hasUserRoleInAnyProject: {
        user: false,
        techAdmin: false,
        finAdmin: false,
        invoiceApprover: false
      },
      checkStaticHash() {
        // fetch(process.env.NODE_ENV === 'development' ? '/version.txt' : '/static/version.txt')
        //   .then((r) => r.json())
        //   .then((staticHash) => {
        //
        //     if (staticHash) {
        //       if (!localStorage.postosFrontendVersion) {
        //         localStorage.postosFrontendVersion = JSON.stringify(staticHash)
        //         // @ts-ignore
        //         window.location.reload(true)
        //       } else if (Number(localStorage.postosFrontendVersion) !== staticHash) {
        //         localStorage.postosFrontendVersion = JSON.stringify(staticHash)
        //         // @ts-ignore
        //         window.location.reload(true)
        //       }
        //     }
        //   })
        //   .catch(() => {
        //     if (localStorage.postosFrontendVersion !== 'null') {
        //       localStorage.postosFrontendVersion = 'null'
        //       // @ts-ignore
        //       window.location.reload(true)
        //     } else {
        //       localStorage.postosFrontendVersion = 'null'
        //     }
        //   })
        fetch('/api/actuator/info')
          .then((r) => r.json())
          .then((result) => {
            if (result && result.git) {
              if (!localStorage.postosBackendVersion) {
                localStorage.postosBackendVersion = JSON.stringify(result.git)
                // @ts-ignore
                window.location.reload(true)
              } else if (localStorage.postosBackendVersion !== JSON.stringify(result.git)) {
                localStorage.postosBackendVersion = JSON.stringify(result.git)
                // @ts-ignore
                window.location.reload(true)
              }
            }
          })
          .catch(() => {
            console.log('Err')
          })
      },
      fakturaFetchUserData: async () => {
        try {
          const response: any = await api.faktura.postosWhoAmI();
          const userDataObj = response.data.data.body.userdata;

          set(
            produce((state) => {
              state.userData = userDataObj;
            }),
          );

          if (userDataObj?.id) {
            await get().checkUserRolesInProjects();
          }

          const userRoles = response.data.data.body.roles;
          if (userRoles && Array.isArray(userRoles)) {
            const mappedUserRoles: UserRoles[] = userRoles.map(
              (userRole: any) => ({
                createdOn: userRole.createdOn,
                id: userRole.id,
                isAssignableToUser: userRole.isAssignableToUser,
                isSystem: userRole.isSystem,
                modifiedOn: userRole.modifiedOn,
                name: userRole.name,
              }),
            );

            set(
              produce((state) => {
                state.userRoles = mappedUserRoles;
              }),
            );
          } else {
            set(
              produce((state) => {
                state.userRoles = [];
              }),
            );
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      },
      //TODO Probably better would be to move everything related to project roles to a separate store
      // but we also need user projects from the beginning
      checkUserRolesInProjects: async () => {
        try {
          const response: any = await api.projectUsers.postosGetProjectUser();
          const projectUsersOfTheUser: PostosProjectsUsersBODYValidationRule[] 
            = response?.data?.data?.body?.project_users || [];

          const projectsIdsRoleUser = projectUsersOfTheUser
              .filter((projectUser) => projectUser.role === ProjectUserRoles.USER)
              .map((projectUser => projectUser.project_id));
          const projectsIdsRoleTechAdmin = projectUsersOfTheUser
              .filter((projectUser) => projectUser.role === ProjectUserRoles.ADMIN)
              .map((projectUser => projectUser.project_id));
          const projectsIdsRoleFinAdmin = projectUsersOfTheUser
              .filter((projectUser) => projectUser.role === ProjectUserRoles.FINANCIAL_ADMIN)
              .map((projectUser => projectUser.project_id));
          const projectsIdsRoleInvoiceApprover = projectUsersOfTheUser
              .filter((projectUser) => projectUser.role === ProjectUserRoles.INVOICE_APPROVER)
              .map((projectUser => projectUser.project_id));

          set(
            produce((state) => {
              state.allProjectUsersOfTheUser = projectUsersOfTheUser;
              state.allUserProjectsIds = projectUsersOfTheUser.map((projectUser) => projectUser.project_id);
              state.userProjectsIdsByRole.user = projectsIdsRoleUser;
              state.userProjectsIdsByRole.techAdmin = projectsIdsRoleTechAdmin;
              state.userProjectsIdsByRole.finAdmin = projectsIdsRoleFinAdmin;
              state.userProjectsIdsByRole.invoiceApprover = projectsIdsRoleInvoiceApprover;

              state.hasUserRoleInAnyProject = {
                user: projectsIdsRoleUser.length > 0 || false,
                techAdmin: projectsIdsRoleTechAdmin.length > 0 || false,
                finAdmin: projectsIdsRoleFinAdmin.length > 0 || false,
                invoiceApprover: projectsIdsRoleInvoiceApprover.length > 0 || false
              }
            })
          );
        } catch (error) {
          console.error('Error fetching user project roles:', error);
        }
      }
    }),
);

export default useUserDataRolesStore;
