import {
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableReports from './components/SearchTableReports';
import SearchTableMonthlyReport from './components/SearchTableMonthlyReport';
import SearchTableByResource from './components/SearchTableByResource';
import SearchTableTotalsByResource from './components/SearchTableTotalsByResource';
import { t } from 'i18next';
import { useReportsStore } from "../../../contexts/globalStoreReports";
import { useEffect, useMemo } from "react";
import { useProjectStore } from "../../../contexts/globalStoreProjects";
import useUserDataRolesStore from 'contexts/authStore';
import useLoaderStore from 'contexts/globalStore';
import SearchTableProjectReports from './components/SearchTableProjectReports';

export default function Reports() {
  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);

  const fetchProjects = useProjectStore((state) => state.fetchProjects);
  const projects = useProjectStore((state) => state.projects);

  const set = useReportsStore((state) => state.set);

  const userData = useUserDataRolesStore((state) => state.userData);
  const userProjectsIdsByRole = useUserDataRolesStore((state) => state.userProjectsIdsByRole);

  useEffect(() => {
    let isCurrent = true;
    setLoadingState(true);

    const fetchData = async () => {
      await fetchProjects(null, null, null, null, true);


      set((state: any) => {
        state.isInitDone = true
      })
    };
    if (isCurrent) {
      fetchData().then(() => setLoadingState(false));
    }

    return () => {
      isCurrent = false;
    };
  }, [userData, fetchProjects, set, setLoadingState, userProjectsIdsByRole.techAdmin, userProjectsIdsByRole.finAdmin]);

  const userAdminProjects = useMemo(() => {
    if (!userProjectsIdsByRole.techAdmin || !projects) return [];
    return projects.filter((project) => userProjectsIdsByRole.techAdmin.includes(project.id));
  }, [userProjectsIdsByRole.techAdmin, projects]);

  const userFinancialAdminProjects = useMemo(() => {
    if (!userProjectsIdsByRole.finAdmin || !projects) return [];
    return projects.filter((project) => userProjectsIdsByRole.finAdmin.includes(project.id));
  }, [userProjectsIdsByRole.finAdmin, projects]);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      {userAdminProjects?.length ?
        <Tabs variant="enclosed">
          <TabList>
            <Tab>{t('report', { ns: ['labels'] })}</Tab>
            {userFinancialAdminProjects?.length ? (<>
              <Tab data-test-id="report-monthly-tab">{t('monthlyReport', { ns: ['labels'] })}</Tab>
              <Tab data-test-id="report-by-resource-tab">{t('byResource', { ns: ['labels'] })}</Tab>
              <Tab data-test-id="report-project-tab">{t('projectsReport', { ns: ['labels'] })}</Tab>
            </>) : null}
          </TabList>

          <TabPanels>
            <TabPanel>
              <Card px="0px">
                <SearchTableReports />
              </Card>
              <br />
              {userFinancialAdminProjects?.length ? (
                <Card px="0px">
                  <SearchTableTotalsByResource />
                </Card>) : null}
            </TabPanel>
            {userFinancialAdminProjects?.length ? (
              <TabPanel>
                <Card px="0px">
                  <SearchTableMonthlyReport />
                </Card>
              </TabPanel>
            ) : null}
            {userFinancialAdminProjects?.length ? (
              <TabPanel>
                <Card px="0px">
                  <SearchTableByResource />
                </Card>
              </TabPanel>
            ) : null}
            {userFinancialAdminProjects?.length ? (
              <TabPanel>
                <Card px="0px">
                  <SearchTableProjectReports />
                </Card>
              </TabPanel>
            ) : null}
          </TabPanels>
        </Tabs>
        : loadingState
          ? <Spinner />
          : <Text>{t('youHaveNoAdminProjects', { ns: ['labels'] })}</Text>
      }
    </Flex>
  );
}
