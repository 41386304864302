export const labelsEn = {
  username: 'Username',
  password: 'Password',
  forgotPassword: 'Forgot password?',
  stayLoggedIn: 'Stay logged in',
  name: 'Name',
  status: 'Status',
  actions: 'Actions',
  active: 'Active',
  inactive: 'Inactive',
  phone: 'Phone',
  email: 'Email',
  search: 'Search',
  export: 'Export',
  from: 'From',
  to: 'To',
  action: 'Action',
  allNone: 'All/None',
  first: 'First',
  last: 'Last',
  model: 'Model',
  default: 'Default',
  registeredOn: 'Registered on',
  lastModifiedOn: 'Last modified on',
  createdOn: 'Created on',
  modifiedOn: 'Modified on',
  hour: 'Hour',
  minute: 'Minute',
  mandatory: 'Mandatory',
  response: 'Response',
  notifications: 'Notifications',
  text: 'Text',
  themes: 'Themes',
  details: 'Details',
  people: 'People',
  addresses: 'Addresses',
  download: 'Download',
  instance: 'Instance',
  backendversion: 'Backend',
  ipAddress: 'IP Address',
  portNumber: 'Port Number',
  changePassword: 'Change password',
  system: 'System',
  created: 'Created',
  created2: 'created',
  updated: 'Updated',
  updated2: 'updated',

  cancellation: 'Cancel',
  print: 'Print',
  register: 'Register',
  number: 'No',
  mode: 'Mode',
  dark: 'Dark',
  fontSizes: 'Font Sizes',
  theme: 'Themes',
  none: 'None',
  successful: 'Successful',
  warning: 'Warning',
  open: 'Open',
  failed: 'Failed',
  activeOnly: 'Active Only',
  keyType: 'Key Type',
  payload: 'Payload',
  privateKey: 'Private Key',
  signatureAlgorithm: 'Signature Algorithm',
  url: 'Url',
  maxPoolSize: 'Max Pool Size',
  host: 'Host',
  port: 'Port',
  sid: 'SID',
  token: 'Token',
  apiVersion: 'API Version',
  appId: 'APP ID',
  aud: 'AUD',
  id: 'ID',
  maxProcessingDurationInMSec: 'Max Processing Duration in MSec',
  maxProcessingDuration: 'Max Processing Duration',
  process: 'Process',
  triggerCondition: 'Trigger Condition',
  seconds: 'Seconds',
  minutes: 'Minutes',
  minutesInterval: 'Minutes Interval',
  hours: 'Hours',
  hoursInterval: 'Hours Interval',
  dayOfMonth: 'Day Of Month',
  month: 'Month',
  chooseMonth: 'Please choose a month',
  dayOfWeek: 'Day Of Week',
  method: 'Method',
  maxRequestSize: 'Max Request Size',
  frontEnd: 'Front end',
  searchByName: 'Search By Name',
  finishedOn: 'Finished On',
  startedOn: 'Started On',
  instanceId: 'Instance ID',
  eventId: 'Event ID',
  threadId: 'Thread ID',
  date: 'Date',
  clientId: 'Client ID',
  clientSecret: 'Client Secret',
  selectProcessFirst: 'Select process first',
  event: 'Event',
  exceptionMessage: 'Exception Message',
  exceptionStackTrace: 'Exception Stack Trace',
  stepId: 'Step ID',
  maxSimultaneousExecutions: 'Max Simultaneous Executions',
  saveProcessToEditSteps: 'Save Process To Edit Steps',
  defaultStepsName: 'Steps Name',
  properties: 'Properties',
  stepProperties: 'Step Properties',
  enabled: 'Step enabled',
  query: 'Query',
  targetObject: 'Target Object',
  credential: 'Credential',
  recordName: 'Record Name',
  triggerId: 'Trigger ID',
  type: 'Type',
  processingStartOn: 'Processing started on',
  showCache: 'Cache',
  list: 'List',
  json: 'JSON',
  async: 'Asynchronous execution',
  input: 'Input',
  output: 'Output',
  js: 'JavaScript',
  switch: 'Switch',
  collectionName: 'Collection Name',
  queryUpdate: 'Query Update',
  mailTemplate: 'Mail Template',
  variables: 'Variables',
  subject: 'Subject',
  restResponseCode: 'REST response code target object',
  targetUrl: 'Target Url',
  timeout: 'Timeout in MSec',
  body: 'Body',
  headers: 'Headers',
  queryParams: 'Query Params',
  channelId: 'Channel ID',
  message: 'Message',
  logsTtlInMSec: 'Time-to-live for logs',
  stepName: 'Step Name',
  copy: 'Copy',
  steps: 'Steps',
  filter: 'Filter',
  role: 'Role',
  roles: 'Roles',
  step: 'Step',
  childStep: 'Child Step',
  permissions: 'Permissions',
  code: 'Code',
  assignable: 'Assignable',
  yes: 'Yes',
  no: 'No',
  textTemplate: 'Text Template',
  en: 'English',
  de: 'Deutsch',
  language: 'Language',
  connectionTimeout: 'Connection Timeout',
  writeTimeout: 'Write Timeout',
  template: 'Template',
  grant_type: 'Grant type',
  refresh_token: 'Refresh token',
  setPasswordUrl: 'Set Password Url',
  maxTriggerHttpThreads: 'Max Trigger Http Threads',
  maxTriggerHttpProcessingInMSec: 'Max Trigger http Processing',
  maxProcessingThreads: 'Max Processing Threads',
  permissionType: 'Permission Type',
  unit: 'Unit',
  changeableTimeout: 'Timeout',
  secondsInterval: 'Seconds Interval',
  account: 'Account',
  yourAccount: 'Your Account',
  setPassword: 'Set Password',
  confirmPassword: 'Confirm Password',
  baseUrlRest: 'Base Url Rest',
  stepType: 'Step Type',
  gdpr: 'GDPR Relevant',
  history: 'History',
  notSet: 'Not set',
  defaultLocale: 'Default Locale',
  global: 'Global',
  local: 'Local',
  est: 'Trace',
  addProcessName: 'Add Process Name',
  visualId: 'Visual ID',
  bg: 'Bulgarian',
  zh: 'Chinese (Simplified)',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  nl: 'Dutch',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  el: 'Greek',
  hu: 'Hungarian',
  ga: 'Irish',
  it: 'Italian',
  lv: 'Latvian',
  lt: 'Lithuanian',
  mt: 'Maltese',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  sk: 'Slovak',
  sl: 'Slovene',
  es: 'Spanish',
  sv: 'Swedish',
  tr: 'Turkish',
  uk: 'Ukrainian',
  validation: 'Validation',
  permission: 'Permission',
  exist: 'Exist',
  missingParams: 'Missed Required Resources',
  fileLoaded: 'File Loaded',
  scriptEngine: 'Script Engine',
  dataType: 'Data Type',
  ruleType: 'Rule Type',
  fields: 'Fields',
  fieldName: 'Field Name',
  required: 'Required',
  rules: 'Rules',
  field: 'Field',
  rule: 'Rule',
  validationRules: 'Validation Rules',
  logServerUrl: 'Log Server Url',
  logs: 'Logs',
  apiKey: 'Api Key',
  listOfCodes: 'List of HTTP codes',
  logsServer: 'Log Server',
  debug: 'Debug',
  processSettingName: 'Setting',
  UPLOAD: 'Upload',
  DOWNLOAD: 'Download',
  descriptions: 'Descriptions',
  loadDirection: 'Load Direction',
  encrypt: 'Encrypt',
  restResponseContentTargetObject: 'REST response content target object',
  enableStepLog: 'Logging',
  urlForAdmin: 'Healthcheck URL for admin',
  urlForProcessingService: 'Healthcheck URL for the processing service',
  searchByModuleName: 'Search by Module Name',
  description: 'Description',
  internalNote: 'Internal Note',
  basicAccessAuthentication: 'Allow Basic Authentication',
  upDownload: 'Up/Download',
  remoteId: 'Remote Id',
  exception: 'Exception',
  className: 'Class Name',
  checksum: 'Checksum',
  stepTypes: 'Step Types',
  plugin: 'Plugin',
  selectPlugin: 'Select Plugin First',
  processLogs: 'Process logs',
  openProcessLogs: 'Open process logs',
  accessKeyId: 'Access Key Id',
  region: 'Region',
  s3Bucket: 'S3 Bucket',
  secretKey: 'Secret Key',
  content: 'Content',
  key: 'Key',
  path: 'Path',
  events: 'Events',
  backEnd: 'Back end',
  isCacheStepLogsEnabled: 'Cache storage',
  isStepLogsEnabled: 'Logging',
  enableCacheLog: 'Cache storage',
  tos: 'Terms of Service',
  privacyPolicy: 'Privacy Policy',
  passphrase: 'Passphrase',
  queryConsole: 'Query Console',
  console: 'Console',
  affectedRows: 'Affected Rows',
  success: 'Success',
  respondSent: 'Respond sent',
  resultDetails: 'Result Details',
  engine: 'Engine',
  isBodyScript: 'Body Script',
  groovy: 'Groovy',
  last15MinutesCount: 'Last 15 minutes',
  last24HoursCount: 'Last 24 hours',
  last60MinutesCount: 'Last 60 minutes',
  todayCount: 'Today',
  executions: 'Executions',
  eventStates: 'Events states',
  childEventIdPath: 'Child event ID target object',
  folder: 'Folder',
  sizeInBytes: 'Size In Bytes',
  readType: 'Read Type',
  maxResults: 'Max Results',
  recipient: 'Recipient',
  sender: 'Sender',
  emailId: 'Email Id',
  locale: 'Locale',
  heartbeat: 'Heartbeat',
  user: 'User',
  selectUser: 'Select User*',
  overview: 'Overview',
  review: 'Review',
  title: 'Title',
  rating: 'Rating',
  reviews: 'Reviews',
  submitReview: 'Submit Review',
  metricServerUrl: 'Metric Server Url',
  visualisationServerUrl: 'Visualisation Server Url',
  metricServer: 'Metric Server',
  visualisationServer: 'Visualisation Server',
  internalJobs: 'Internal jobs',
  started: 'Started',
  hideChildren: 'Hide children',
  showChildren: 'Show children',
  diagrams: 'Diagrams',
  genericSubType: 'Member type',
  userId: 'User ID',
  filters: 'Filters',
  source: 'Source Object',
  startDate: 'Start',
  endDate: 'End',
  startTime: 'Start Time',
  hoursPerMonth: 'Max hours per Month',
  amountOfHours: 'Amount Of Hours',
  hourFormat: 'Hour Format',
  duration: 'Duration',
  amount: 'Amount',
  decimals: 'Decimals',
  endTime: 'End Time',
  addUsers: 'Add Users',

  project: 'Project',
  selectProject: 'Select Project',
  createProject: 'Create Project',
  editProject: 'Edit Project',
  noUsersInProject: 'The project has no users',
  noTasksInProject: 'The project has no tasks',
  noSuchTaskInProject: 'The project has no such task',
  noSuchUserInProject: 'The project has no such user',
  cantChangeBookingToProject: 'You can not change the booking to this project',
  youHaveNoAdminProjects: "There are no projects where you have admin rights.",
  noProjectsNoBookings: 'You are not a user of any project and can not see any bookings.',
  isActive: 'Active',
  onlyActiveProjects: 'Only active projects',
  P14D: 'Payable in 14 days',
  P14D_2P_30D: 'Payable within 14 calendar days (2% discount) or 30 calendar days without deduction',
  P30D: 'Payable within 30 days without deduction',
  P90D: 'Payable within 90 days without deduction',
  DOR: 'Due on receipt',

  company: 'Company',
  updateCompany: 'Update Company',
  createCompany: 'Create Company',
  selectCompany: 'Select Company',
  pleaseSelectCompany: 'Please select a company',
  dontHaveCompanies: 'You don\'t have any companies yet. Please add one at the companies page.',
  noContactsInCompany: 'You don\'t have contacts in this company. Please add one at the company page.',
  worklog: 'Worklog',
  newEntry: 'New Entry',
  comment: 'Comment',
  cantWorklogBeforeCompanyCreate: 'You can\'t worklog before saving a company.',

  costCenterBillable: 'Billable cost center',
  costCenterNonBillable: 'Non billable cost center',
  isBillable: 'Is billable',

  person: 'Person',
  addPerson: 'Add Person',
  fullname: 'Fullname',
  selectContacts: 'Select Contacts',
  selectContact: 'Select Contact',
  cantChange: "You can't change this",

  address: 'Address',
  addAddress: 'Add Address',
  noAddressesForPerson: `The company doesn't have any addresses.
  You have to add at least one address and save the form to add a person`,
  companyAddressRequired: 'You have to add at least one address',
  cantDeleteAddress: 'You can not delete this address. There are people with this address.',
  addressLine: 'Address Line',
  isBillingAddress: 'Billing Address',

  till: 'Till',
  reject: 'Reject',
  statusChange: 'Status change',
  statusChanged: 'Status changed',
  contact: 'Contact',
  companyName: 'Company Name',
  contactPerson: 'Contact Person',
  contactEmail: 'Contact Email',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  zipCode: 'Zip Code',
  city: 'City',
  country: 'Country',
  registrationNumber: 'Registration Number',
  uid: 'UID',
  vatRate: 'VAT Rate',
  reverseCharge: 'Reverse Charge',
  currency: 'Currency',
  userRate: 'User Rate',
  error: 'Error',

  hello: 'Hello',

  clientRate: 'Client Rate',
  report: 'Report',
  reports: 'Reports',
  monthlyReport: 'Monthly Report',
  total_duration: 'Total Duration',
  total_resource_cost: 'Total Resource Cost',
  total_client_cost: 'Total Client Cost',
  totalsByResource: 'Totals by resource',
  projectTypeHelp: 'Project type Help',
  byResource: 'By Resource',
  yearly: 'Yearly',
  projectsReport: 'Projects Report',
  totalProjectAmount: 'Total Project Amount',
  totalProjectInvoicedAmount: 'Total Project Invoiced Amount',
  entryByTimeframe: 'Entry by timeframe',
  profit: 'Profit',
  client_rate: 'Client Rate*',
  rates: 'Rates',
  create: 'Create',
  invoice_number: 'Invoice Number',
  is_cancelation: 'Is Cancellation',
  addTasks: 'Add',
  task: 'Task',
  tasks: 'Tasks',
  costCenter: 'Cost Center',
  costCenters: 'Cost Centers',
  selectCostCenter: 'Select Cost Center',
  timesheetOnInvoice: 'Timesheet on Invoice',
  terms_of_payment: 'Terms of Payment',
  dateFrom: 'Date From',
  dateTill: 'Date Till',
  invoice: 'Invoice',
  invoices: 'Invoices',
  noInvoicesData: 'No invoices data',
  areYouSureCancelInvoice: 'Are you sure you want to cancel this invoice?',
  cancelInvoice: 'Cancel Invoice',
  rounding: 'Rounding',
  roundingType: 'Rounding type',
  is_canceled: 'Is Canceled',
  reference: 'Reference',
  signIn: 'Log In',
  loading: 'Loading...',
  next: 'Next',
  prev: 'Prev',
  selectRole: 'Select Role*',
  selectAddress: 'Select Address',
  submit: 'Submit',
  titlePlaceHolder: 'Title Name',
  hourPlaceHolder: 'Max 4096 Hours',
  addressPlaceHolder: 'Street',
  zipCodePlaceHolder: 'Zip Code',
  precise: 'Precise',
  select: 'Select',
  home: 'Home',
  projects: 'Projects',

  bookings: 'Bookings',
  booking: 'Booking',
  createBooking: 'Create Booking',
  editBooking: 'Edit Booking',

  configuration: 'Configuration',
  showing: 'Showing',
  toEntry: 'to',
  ofEntry: 'of',
  entries: 'entries',
  selectHeader: 'SELECT',
  userHeader: 'USER',
  nameHeader: 'NAME',
  projectHeader: 'PROJECT',
  dateFromHeader: 'DATE FROM',
  dateTillHeader: 'DATE TILL',
  durationHeader: 'DURATION',
  taskHeader: 'TASK',
  descriptionHeader: 'DESCRIPTION',
  totalDurationHeader: 'TOTAL DURATION',
  totalAmountHeader: 'TOTAL AMOUNT',
  totalResourceCostHeader: 'TOTAL RESOURCE COST',
  totalResourceClientCostHeader: 'TOTAL CLIENT COST',
  monthHeader: 'MONTH',
  invoiceNumberHeader: 'INVOICE NUMBER',
  isCancelationHeader: 'IS CANCELLATION',
  isCanceledHeader: 'IS CANCELED',
  clientRateHeader: 'CLIENT RATE',
  totalClientCostHeader: 'TOTAL CLIENT COST',
  profitHeader: 'PROFIT',
  totalsProvisionals: 'Totals (provisionals)',
  noUsersWithRates: 'No users with rates. You can add some at the permissions page.',
};
