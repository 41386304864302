import { UserRoles } from "contexts/authStore";
import { ProjectUser } from "contexts/globalStoreProjects";
import { AdminRoles, CreatorRoles, ProjectUserRoles } from "enums/userRoles";

export const findProjectUsersByUserId = (
  projectUsers: ProjectUser[],
  userId: number,
  projectId: number
) => {
  const projectUser = projectUsers.filter(projectUserItem =>
    projectUserItem.userId === userId
      && projectUserItem.projectId === projectId
    );
  return projectUser;
}

// export const checkIsUserAdminOnProject = (
//   projectUsers: ProjectUser[],
//   userId: number,
//   projectId: number,
//   financial = false
// ) => {
//   if (projectId) {
//     const userInProject = findProjectUsersByUserId(projectUsers, userId, projectId);
  
//     return financial
//     ? userInProject.some(projectUser => projectUser.role === ProjectUserRoles.FINANCIAL_ADMIN)
//     : userInProject.some(projectUser => projectUser.role === ProjectUserRoles.ADMIN);
//   }
//   return false;
// }

export const checkIsUserInvoiceApproverOnProject = (
  projectUsers: ProjectUser[],
  userId: number,
  projectId: number
) => {
  if (projectId) {
    const userInProject = findProjectUsersByUserId(projectUsers, userId, projectId);
    return userInProject.some(projectUser => projectUser.role === ProjectUserRoles.INVOICE_APPROVER)
  }
  return false;
}

export const checkIfUserFakturaAdmin = (userRoles: UserRoles[]) => {
  return userRoles.some((role: any) => role.name === AdminRoles.FAKTURA_ADMIN);
}

export const checkIfUserProjectCreator = (userRoles: UserRoles[]) => {
  return userRoles.some((role: any) => role.name === CreatorRoles.FAKTURA_PROJECT_CREATOR);
}

export const checkIfUserCostCenterCreator = (userRoles: UserRoles[]) => {
  return userRoles.some((role: any) => role.name === CreatorRoles.COMPANY_CREATOR);
}

export const checkIfUserCompanyCreator = (userRoles: UserRoles[]) => {
  return userRoles.some((role: any) => role.name === CreatorRoles.COSTCENTER_CREATOR);
}