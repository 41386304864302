import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Stack,
  Text,
  Flex,
  Container,
  SimpleGrid,
  InputGroup,
  Input,
  InputRightAddon,
  Card,
  useColorModeValue,
  FormLabel,
} from '@chakra-ui/react';
import { ProjectUser, useProjectStore } from 'contexts/globalStoreProjects';
import { t } from 'i18next';
import * as yup from 'yup';
import useUserDataRolesStore from 'contexts/authStore';
import { ProjectUserRoles } from 'enums/userRoles';
import { checkIfUserFakturaAdmin } from 'utils/roleHelpers';
import useFormErrorsStore from 'contexts/formErrorsStore';
import { User, useFakturaUsersStore } from 'contexts/globalStoreFakturaUsers';

const rateAdminConditionSchema = yup
  .number()
  .typeError('typeErrorNumber')
  .required('rateRequired')
  .min(1, "rateRequired");

const validationSchemaSingleUserWithRates = yup.object().shape({
  userRate: rateAdminConditionSchema,
  clientRate: rateAdminConditionSchema,
});

const validationSchema = yup.object().shape({
  users: yup
    .array()
    .of(validationSchemaSingleUserWithRates)
});

export const ProjectRates = ({
  viewOnly, isUserFinancialAdminOnProject
}: {
  viewOnly: boolean,
  isUserFinancialAdminOnProject: boolean
}) => {
  const projectUsers = useProjectStore((state) => state.projectUsers);
  const projectUsersWithRates = useMemo(() => [...projectUsers.filter(
    (projectUser: ProjectUser) => projectUser.role === ProjectUserRoles.USER
  )], [projectUsers]);

  const fakturaUsers = useFakturaUsersStore((state: any) => state.users);
  const userRoles = useUserDataRolesStore((state) => state.userRoles);
  const isUserFakturaAdmin = useMemo(() => checkIfUserFakturaAdmin(userRoles), [userRoles]);

  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteErrorsContainingString = useFormErrorsStore((state) => state.deleteErrorsContainingString);

  const handleUserDataChange = useProjectStore(
    (state) => state.changeUserInProject,
  );

  const [eachFieldProjectUserValidation, setEachFieldProjectUserValidation] =
    useState([]);

  const getValidationError = useCallback((index: number, fieldName: string) => {
    return eachFieldProjectUserValidation?.[index]?.[fieldName]?.error || null
  }, [eachFieldProjectUserValidation]);


  const handleUserData = useCallback((index: number, field: any, value: any) => {
    handleUserDataChange(index, field, value);
  }, [handleUserDataChange]);

  const setTrue = useProjectStore((state) => state.setToTrue);

  const validateFormData = useCallback(async (formData: any) => {
    try {
      await validationSchema.validate(formData);
      setTrue('ratesValidation', true);
    } catch (error) {
      setTrue('ratesValidation', false);
    }
  }, [setTrue]);

  useEffect(() => {
    validateFormData({ users: projectUsersWithRates });
  }, [validateFormData, projectUsersWithRates]);

  const validateSingleField = useCallback(async (path: string, user: ProjectUser) => {
    try {
      // We need to validate the whole object to check the role
      await validationSchemaSingleUserWithRates.validateAt(path, user);
      return { valid: true, error: '' };
    } catch (e: any) {
      return { valid: false, error: e?.errors?.join(', ') || '' };
    }
  }, []);

  useEffect(() => {
    setEachFieldProjectUserValidation([]);

    const mappedResult = projectUsersWithRates
      .map(async (user: ProjectUser) => ({
        userRate: await validateSingleField('userRate', user),
        clientRate: await validateSingleField('clientRate', user),
      }));

    Promise.all(mappedResult).then((res) =>
      setEachFieldProjectUserValidation(res),
    );
  }, [projectUsersWithRates, validateSingleField]);

  useEffect(() => {
    const userError = t('userError', { ns: ['errors'] });
    const usersWithErrors = eachFieldProjectUserValidation.filter(
      (user: ProjectUser) => Object.values(user).some(
        (field: { valid: boolean, error: string }) => !field.valid
      ),
    );

    if (usersWithErrors.length > 0) {
      const errorsFields = usersWithErrors.map(
        (user: any) => Object.values(user)
      )

      const errors = errorsFields
        .filter((fieldsArr: any) => fieldsArr.some((field: any) => !field.valid))
        .map((fieldsArr: any) => fieldsArr.filter((field: any) => !field.valid))
        .flat();

      const errorsForShow: string[] = errors.map(
        (field: any) => `${userError}: ${t(field.error, { ns: ['hints'] })}`
      )

      setErrors(errorsForShow);
    } else {
      deleteErrorsContainingString(userError);
    }
  }, [deleteErrorsContainingString, eachFieldProjectUserValidation, setErrors]);

  const textColor = useColorModeValue('navy.700', 'white');

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} gap="4" mt={2}>
      {projectUsersWithRates && projectUsersWithRates.length ?
        projectUsersWithRates.map((projectUser: ProjectUser, index: number) => {
          const user = fakturaUsers?.find((user: User) =>
            projectUser.userId === user.userId);

          const projectUserInStore = projectUsers.find((storedProjectUser: ProjectUser) =>
            projectUser.userId === storedProjectUser.userId && storedProjectUser.role === ProjectUserRoles.USER
          );

          const projectUserStoreIndex = projectUsers.findIndex((storedProjectUser: ProjectUser) =>
            projectUser.userId === storedProjectUser.userId && storedProjectUser.role === ProjectUserRoles.USER
          );

          return user?.userId ? (<Container key={'user-rates-' + user.userId + '-' + index}>
            <Card p={4} pb="1" h={'180px'}>
              <Stack
                alignItems="center"
                mb={3}
              >
                {user ? (<><Text data-test-id={`rate-${user.userId}-name`} color={textColor} fontSize="md" fontWeight="500">
                  {user.name || ''}
                </Text>
                  <Text color={textColor} fontSize="md" fontWeight="500">
                    ({user.userId || ''})
                  </Text></>) : null}
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} mt={'auto'}>
                <Flex direction="column" w="100%">
                  <FormLabel
                    ms="10px"
                    fontSize="sm"
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    {t('userRate', { ns: ['labels'] }) + '*'}
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      value={projectUserInStore.userRate || 0}
                      color={textColor}
                      placeholder={t('userRate', { ns: ['labels'] })}
                      disabled={
                        viewOnly
                        || (!isUserFakturaAdmin && !isUserFinancialAdminOnProject)
                      }
                      data-test-id={`rate-${user.userId}-user-rate-input`}
                      onChange={(event) =>
                        handleUserData(projectUserStoreIndex, 'userRate', Number(event.target.value))}
                    />
                    <InputRightAddon children="/h" />
                  </InputGroup>
                  <Text data-test-id={`rate-${user.userId}-user-rate-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && getValidationError(index, 'userRate')
                      ? t(getValidationError(index, 'userRate'), { ns: ['hints'] }) : null}{' '}
                    &nbsp;
                  </Text>
                </Flex>
                <Flex direction="column" w="100%">
                  <FormLabel
                    ms="10px"
                    fontSize="sm"
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    {t('clientRate', { ns: ['labels'] }) + '*'}
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      color={textColor}
                      value={projectUserInStore.clientRate || 0}
                      placeholder={t('clientRate', { ns: ['labels'] })}
                      disabled={
                        viewOnly
                        || (!isUserFakturaAdmin && !isUserFinancialAdminOnProject)
                      }
                      data-test-id={`rate-${user.userId}-client-rate-input`}
                      onChange={(event) => handleUserData(
                        projectUserStoreIndex,
                        'clientRate',
                        Number(event.target.value)
                      )}
                    />
                    <InputRightAddon children="/h" />
                  </InputGroup>
                  <Text data-test-id={`rate-${user.userId}-client-rate-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                    {showErrors && getValidationError(index, 'clientRate')
                      ? t(getValidationError(index, 'clientRate'), { ns: ['hints'] }) : null}{' '}
                    &nbsp;
                  </Text>
                </Flex>
              </Stack>
            </Card>
          </Container>) : null
        }) : t('noUsersWithRates', { ns: ['labels'] })}
    </SimpleGrid>
  );
};

export default ProjectRates;
