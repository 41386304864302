import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Stack,
  FormControl,
  Text,
  Flex,
  IconButton,
  Container,
  SimpleGrid,
  InputGroup,
  Input,
  Select,
  Card,
  useColorModeValue,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { Address, Person, useCompanyStore } from 'contexts/globalStoreCompanies';
import { t } from 'i18next';
import * as yup from 'yup';
import useFormErrorsStore from 'contexts/formErrorsStore';

const personValidationObj = {
  fullname: yup
    .string()
    .required('nameRequired')
    .min(2, 'minLength2'),
  phone_number: yup
    .string()
    .transform((val, originalVal) => (originalVal === '' ? null : val))
    .matches(/^[+]?[0-9]{1,}$/, 'phoneShouldBeValid')
    .nullable(),
  contact_email: yup
    .string()
    .transform((val, originalVal) => (originalVal === '' ? null : val))
    .email('emailShouldBeValid')
    .nullable(),
  address_id: yup
    .number().required('addressRequired'),
  company_id: yup
    .number().required('companyRequired'),
  isNew: yup.boolean().optional(),
}

const validationSchema = yup.object().shape({
  people: yup
    .array()
    .of(
      yup.object().shape(personValidationObj),
    )
});

const validationSchemaSinglePerson = yup.object().shape(personValidationObj);

export const CompanyPeople = ({ viewOnly }: { viewOnly: boolean }) => {
  const companyId = useCompanyStore((state) => state.company.id);
  const companyPeople = useCompanyStore((state) => state.people);
  const addresses = useCompanyStore((state) => state.addressesInDB);

  const addNewPersonToCompany = useCompanyStore(
    (state) => state.addPersonToCompany,
  );
  const deletePersonFromCompanyList = useCompanyStore(
    (state) => state.deletePersonFromCompany,
  );

  const handlePersonDataChange = useCompanyStore(
    (state) => state.changePersonInCompany,
  );

  const [eachFieldCompanyPersonValidation, setEachFieldCompanyPersonValidation] =
    useState([]);

  const handleAdd = useCallback(() => {
    addNewPersonToCompany({
      id: null,
      fullname: null,
      contact_email: null,
      phone_number: null,
      company_id: companyId || null,
      address_id: addresses?.length ? addresses[0].id : null,
    });
  }, [addNewPersonToCompany, addresses, companyId]);

  const handleDeletePerson = (indexToDelete: number) => {
    deletePersonFromCompanyList(indexToDelete);
  };

  const handlePersonData = (index: number, field: any, value: any) => {
    handlePersonDataChange(index, field, value);
  };

  const setTrue = useCompanyStore((state) => state.setToTrue);

  const validateFormData = useCallback(async (formData: any) => {
    try {
      await validationSchema.validate(formData);
      setTrue('peopleValidation', true);
    } catch (error) {
      setTrue('peopleValidation', false);
    }
  }, [setTrue]);

  const validateSingleField = async (path: string, input: any) => {
    // `reach()` pulls out a child schema so we can test a single path
    const field: any = yup.reach(validationSchemaSinglePerson, path);

    try {
      await field.validate(input);
      return { valid: true, error: '' };
    } catch (e: any) {
      return { valid: false, error: e.errors.join(', ') };
    }
  };

  useEffect(() => {
    const mappedResult = companyPeople?.length ? companyPeople.map(async (person: Person) => {
      const validatedEmail = person.contact_email ? await validateSingleField('contact_email', person.contact_email) : null;
      const validatedPhone = person.phone_number ? await validateSingleField('phone_number', person.phone_number) : null;
      return {
        fullname: await validateSingleField('fullname', person.fullname),
        contact_email: validatedEmail,
        phone_number: validatedPhone,
        company_id: await validateSingleField('company_id', person.company_id),
        address_id: await validateSingleField('address_id', person.address_id),
      }
    }) : [];

    Promise.all(mappedResult).then((res) =>
      setEachFieldCompanyPersonValidation(res),
    );
  }, [companyPeople]);

  useEffect(() => {
    companyPeople?.length > 0 &&
      validateFormData({ people: companyPeople });
  },
    [validateFormData, companyPeople],
  );

  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteError = useFormErrorsStore((state) => state.deleteError);
  const deleteErrorsContainingString = useFormErrorsStore((state) => state.deleteErrorsContainingString);

  useEffect(() => {
    const peopleError = t('peopleError', { ns: ['errors'] });

    //TODO DRY company addersses, project permissions
    if (companyPeople?.length) {
      const peopleWithErrors = eachFieldCompanyPersonValidation.filter(
        (person: Person) => Object.values(person).some(
          (field: { valid: boolean, error: string }) => field && !field.valid
        ),
      );

      if (peopleWithErrors.length > 0) {
        const errorsFields = peopleWithErrors.map(
          (address: Address) => Object.values(address)
        )

        const errors = errorsFields
          .filter((fieldsArr: any) => fieldsArr.some((field: any) => field && !field.valid))
          .map((fieldsArr: any) => fieldsArr.filter((field: any) => field && !field.valid))
          .flat();

        const errorsForShow: string[] = errors.map(
          (field: any) => `${peopleError}: ${t(field.error, { ns: ['hints'] })}`
        )

        setErrors(errorsForShow);
      } else {
        deleteErrorsContainingString(peopleError);
      }
    }
  }, [deleteError, deleteErrorsContainingString, eachFieldCompanyPersonValidation, companyPeople?.length, setErrors]);

  const textColor = useColorModeValue('navy.700', 'white');

  return (
    <>
      <Flex my={4} pr={4}>
        {addresses?.length && !viewOnly ? (
          <>
            <Button variant="outline" data-testid="add-person-button" onClick={handleAdd}>
              {t('addPerson', { ns: ['labels'] })}
              <AddIcon ml={2} />
            </Button>
          </>
        ) : !viewOnly ? (<Text>{t('noAddressesForPerson', { ns: ['labels'] })}</Text>) : null}
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="4">
        {companyPeople?.length ? [...companyPeople].map((person: Person, index: number) => (
          <Container key={index + 5000}>
            <Card p={4}>
              <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px">
                <InputGroup size="md">
                  <Input
                    value={person.fullname || ''}
                    color={textColor}
                    placeholder={t('fullname', { ns: ['labels'] })}
                    disabled={viewOnly}
                    data-test-id={`person-${index}-fullname-input`}
                    onChange={(event) =>
                      handlePersonData(index, 'fullname', event.target.value)
                    }
                  />
                  <IconButton
                    ml="4"
                    colorScheme="red"
                    aria-label="Delete Select"
                    icon={<DeleteIcon />}
                    isDisabled={viewOnly}
                    onClick={() => handleDeletePerson(index)}
                  />
                </InputGroup>
                <Text data-test-id={`person-${index}-fullname-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                  {showErrors && eachFieldCompanyPersonValidation?.[index]?.['fullname']?.error
                    ? t(eachFieldCompanyPersonValidation[index]['fullname'].error, { ns: ['hints'] })
                    : null}
                  &nbsp;
                </Text>
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px">
                <InputGroup size="md">
                  <Input
                    value={person.contact_email || ''}
                    color={textColor}
                    placeholder={t('email', { ns: ['labels'] })}
                    disabled={viewOnly}
                    data-test-id={`person-${index}-email-input`}
                    onChange={(event) =>
                      handlePersonData(index, 'contact_email', event.target.value)
                    }
                  />
                </InputGroup>
                <Text data-test-id={`person-${index}-email-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                  {showErrors && eachFieldCompanyPersonValidation?.[index]?.['contact_email']?.error
                    ? t(eachFieldCompanyPersonValidation[index]['contact_email'].error, { ns: ['hints'] })
                    : null}
                  &nbsp;
                </Text>
              </SimpleGrid>
              <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px">
                <InputGroup size="md">
                  <Input
                    value={person.phone_number || ''}
                    color={textColor}
                    placeholder={t('phone', { ns: ['labels'] })}
                    disabled={viewOnly}
                    data-test-id={`person-${index}-phone-input`}
                    onChange={(event) =>
                      handlePersonData(index, 'phone_number', event.target.value)
                    }
                  />
                </InputGroup>
                <Text data-test-id={`person-${index}-phone-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                  {showErrors && eachFieldCompanyPersonValidation?.[index]?.['phone_number']?.error
                    ? t(eachFieldCompanyPersonValidation[index]['phone_number'].error, { ns: ['hints'] })
                    : null}
                  &nbsp;
                </Text>
              </SimpleGrid>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                alignItems="center"
              >
                <InputGroup>
                  <FormControl>
                    <Stack direction="column">
                      <Select
                        value={person.address_id}
                        disabled={viewOnly}
                        data-test-id={`person-${index}-address-select`}
                        onChange={(event) =>
                          handlePersonData(
                            index,
                            'address_id',
                            Number(event.target.value),
                          )
                        }
                      >
                        {addresses.map(
                          (address: Address, index: number) => (
                            <option key={`person-${person.id}-address-${index}`} value={address.id}>
                              {address.address_line_1}
                            </option>
                          ),
                        )}
                      </Select>
                      <Text data-test-id={`person-${index}-address-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                        {showErrors && eachFieldCompanyPersonValidation?.[index]?.['address_id']?.error
                          ? t(eachFieldCompanyPersonValidation[index]['address_id'].error, { ns: ['hints'] })
                          : null}
                        &nbsp;
                      </Text>

                    </Stack>
                  </FormControl>
                </InputGroup>
              </Stack>
            </Card>
          </Container>
        )) : null}
      </SimpleGrid>
    </>
  );
};

export default CompanyPeople;
