import { NavLink } from 'react-router-dom';
// chakra imports
import {
  Text,
  ListItem,
  useColorModeValue,
  Box,
  Flex,
  HStack,
} from '@chakra-ui/react';
// Assets
import { t } from 'i18next';
import { capitalizeFirstLetter } from 'utils/stringHelpers';

export function CustomMenuLink(props: {
  title: string;
  path: string;
  icon?: JSX.Element;
  mini?: boolean;
  hovered?: boolean;
}) {
  //   Chakra color mode
  let activeColor = useColorModeValue('brand.600', 'white');
  let inactiveColor = useColorModeValue(
    'secondaryGray.800',
    'secondaryGray.400',
  );

  const { title, path, icon, mini, hovered } = props;
  const defaultTitle = capitalizeFirstLetter(title);

  return (
    <NavLink to={{ pathname: path }} >
      {({ isActive }) => {
        if (icon) {
          return (
            <Flex
              align="center"
              justifyContent="space-between"
              w="100%"
              ps="17px"
              mb="0px"
            >
              <HStack
                mb="6px"
                spacing="26px"
              >
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    color={inactiveColor}
                    me="12px"
                    mt="6px"
                  >
                    {icon}
                  </Box>
                  <Text
                    me="auto"
                    color={'secondaryGray.600'}
                    fontWeight="500"
                  >
                    {mini === false
                      ? t(title, { ns: ['routes'], defaultValue: defaultTitle })
                      : mini === true && hovered === true
                        ? t(title, { ns: ['routes'], defaultValue: defaultTitle })
                        : ''}
                  </Text>
                </Flex>
              </HStack>
            </Flex>
          )
        } else {
          return (
            <ListItem
              ms={
                mini === false
                  ? '28px'
                  : hovered === true
                    ? '28px'
                    : '0px'
              }
              display="flex"
              alignItems="center"
              mb="10px"
            >
              <Text
                color={isActive ? activeColor : inactiveColor}
                fontWeight={isActive ? 'bold' : 'normal'}
                fontSize="sm"
              >
                {mini === false
                  ? t(title, { ns: ['routes'], defaultValue: defaultTitle })
                  : hovered === true
                    ? t(title, { ns: ['routes'], defaultValue: defaultTitle })
                    : defaultTitle[0]}
              </Text>
            </ListItem>
          )
        }
      }}
    </NavLink>
  );
}

export default CustomMenuLink;
