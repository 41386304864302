// Chakra imports
import {
  Button,
  Flex, Icon,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  Text,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// Assets
import { CompanyDetails } from './CompanyDetails';
import { CompanyPeople } from './CompanyPeople';
import { CompanyAddresses } from './CompanyAddresses';
import { useCompanyStore } from '../../../../contexts/globalStoreCompanies';
import { t } from 'i18next';
import { MdChevronLeft } from "react-icons/md";
import useUserDataRolesStore from 'contexts/authStore';
import { checkIfUserCompanyCreator } from 'utils/roleHelpers';
import useFormErrorsStore from 'contexts/formErrorsStore';
import CompanyWorklog from './CompanyWorklog';

export default function AddEditCompany() {
  const toast = useToast();
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const userData = useUserDataRolesStore((state) => state.userData);
  const userRoles = useUserDataRolesStore((state) => state.userRoles);

  const fetchSingleCompany = useCompanyStore(
    (state) => state.fetchSingleCompany,
  );
  const validationCheck = useCompanyStore((state) => state.validation);
  const resetValidation = useCompanyStore((state) => state.resetValidation);
  const resetData = useCompanyStore((state) => state.resetAll);
  const submitData = useCompanyStore((state) => state.addOrEditCompanyCall);
  const fetchStatuses = useCompanyStore((state) => state.fetchStatuses);
  const fetchStatusesLocal = useCompanyStore((state) => state.fetchStatusesLocal);

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      if (id) {
        fetchSingleCompany(Number(id))
          .then((res) => {
            if (!res || res.type !== 'success') {
              toast({
                title: t('error', { ns: ['labels'] }),
                description: res.message,
                status: res.type,
              });
            }
          })
          .catch((err) => {
            toast({
              title: t('error', { ns: ['labels'] }),
              description: err.message,
              status: err.type,
            });
          });
      } else {
        resetData();
        resetValidation();
        fetchStatuses();
        fetchStatusesLocal();
      }
    }

    return () => {
      isCurrent = false;
    };
  }, [fetchSingleCompany, fetchStatuses, fetchStatusesLocal, id, location.pathname, resetData, resetValidation, toast]);

  useEffect(() => {
    if (
      validationCheck.detailsValidation &&
      validationCheck.peopleValidation &&
      validationCheck.addressValidation
    ) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  }, [validationCheck]);

  const errors = useFormErrorsStore((state) => state.errors);
  const resetErrors = useFormErrorsStore((state) => state.resetErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const setShowErrors = useFormErrorsStore((state) => state.setShowErrors);

  const handleDataSubmit = useCallback(
    async () => {
      if (!enableSubmit) {
        toast({
          title: t('error', { ns: ['labels'] }),
          description: errors.map((error, index) => (<Text key={index}>
            {error}
          </Text>)),
          duration: null,
          status: 'error',
        });
        setShowErrors(true);
        return;
      } else {
        setShowErrors(false);
      }
      setIsFormSubmitting(true);
      try {
        const res: any = await submitData(userData.id);
        if (res) {
          toast({
            title: res.type === 'success' ? t('success', { ns: ['labels'] }) : t('error', { ns: ['labels'] }),
            description: res.message,
            status: res.type,
          });
          if (res.companyId) {
            navigate(`/companies/edit/${res.companyId}`);
          }
        }
      } catch (err: any) {
        toast({
          title: t('error', { ns: ['labels'] }),
          description: err.message,
          status: err.type,
        });
      };
      setIsFormSubmitting(false);
    }, [enableSubmit, errors, navigate, setShowErrors, submitData, toast, userData.id]
  )

  const isUserCompanyCreator = useMemo(() => checkIfUserCompanyCreator(userRoles), [userRoles]);

  useEffect(() => {
    return () => { resetErrors() };
  }, [resetErrors]);

  const tabComponents = [
    {
      title: t('details', { ns: ['labels'] }),
      component: <CompanyDetails
        viewOnly={!isUserCompanyCreator}
      />,
      validation: validationCheck.detailsValidation
    },
    {
      title: t('addresses', { ns: ['labels'] }),
      component: <CompanyAddresses
        viewOnly={!isUserCompanyCreator}
      />,
      validation: validationCheck.addressValidation
    },
    {
      title: t('people', { ns: ['labels'] }),
      component: <CompanyPeople
        viewOnly={!isUserCompanyCreator}
      />,
      validation: validationCheck.peopleValidation
    },
  ]

  if (isUserCompanyCreator) {
    tabComponents.push({
      title: t('worklog', { ns: ['labels'] }),
      component: <CompanyWorklog
        viewOnly={!isUserCompanyCreator}
      />,
      validation: true,
    });
  }

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} alignItems='center'>
      <Tabs variant="enclosed" maxWidth={1366} width="100%">
        <TabList>
          {tabComponents.map((tab, index) => (
            <Tab data-test-id={`${tab.title}-tab`} key={index} color={showErrors && !tab.validation ? 'red.500' : ''}>{tab.title}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {tabComponents.map((tab, index) => (
            <TabPanel key={index}>
              <Card>
                <Flex justifyContent="space-between" mb={4}>
                  <Button
                    variant="outline"
                    fontSize="sm"
                    borderRadius="12px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    data-test-id="company-back-to-list-button"
                    onClick={() => navigate('/companies')}
                  >
                    <Icon as={MdChevronLeft} mr={1} />
                    {t('back', { ns: ['actions'] })}
                  </Button>
                  {isUserCompanyCreator ? <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    data-test-id="company-submit-button"
                    onClick={handleDataSubmit}
                  >
                    {isFormSubmitting ? (<><Spinner size="sm" mr="2" />
                      {t('loading', { ns: ['labels'] })}</>) :
                      t('submit', { ns: ['labels'] })
                    }

                  </Button> : null}
                </Flex>
                {tab.component}
              </Card>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
