import './assets/css/App.css';
import {
  ChakraProvider, Text,
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUserDataRolesStore from "./contexts/authStore";
import { Stack, Spinner } from '@chakra-ui/react';
import AppRouter from 'router';
import { ThemeProvider } from 'components/themeProvider/ThemeProvider';
import useLoaderStore from 'contexts/globalStore';
// Chakra imports

export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const { i18n } = useTranslation();

  const [hasWindow, setHasWindow] = useState<boolean>(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);

  const fakturaFetchUserData = useUserDataRolesStore((state) => state.fakturaFetchUserData);
  const checkStaticHash = useUserDataRolesStore((state) => state.checkStaticHash);

  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);

  const setLoadingState = useLoaderStore((state) => state.setLoader);

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent && hasWindow) {
      //Get the language from local storage
      const savedLanguage = localStorage.getItem('i18nextLng');

      //If there is a language saved in local storage, set it as the default language
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
      }

      // check static hash
      checkStaticHash();

      // fetch user data
      fakturaFetchUserData().then(() => setLoadingComplete(true));
    }
    return () => {
      isCurrent = false;
    };
  }, [checkStaticHash, fakturaFetchUserData, hasWindow, i18n, setLoadingState]);

  return (
    <ChakraProvider
      theme={currentTheme}
      toastOptions={{
        defaultOptions: {
          duration: 6000,
          isClosable: true,
          position: 'top-right',
          containerStyle: {
            marginRight: 33,
            marginTop: 5
          },
        }
      }}
    >
      {loadingComplete ? (
        <>
          <ThemeProvider>
            <AppRouter />
          </ThemeProvider>
        </>
      ) : (
        <Stack direction='column' spacing={4} w="100vw" h="100vh" align="center" justifyContent="center" >
          <Spinner thickness='2px'
            speed='0.69s'
            color='blue.500'
            size='xl' />
          <Text fontSize="32px" mb="10px">
            Loading...
          </Text>
        </Stack>
      )}
    </ChakraProvider>
  );
}
