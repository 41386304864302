import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  Stack,
  IconButton,
  Spacer,
  useToast
} from '@chakra-ui/react';
import {
  DeleteIcon,
  RepeatIcon,
} from '@chakra-ui/icons';
import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import FakturaTable from 'components/tables/FakturaTable';
import useLoaderStore from 'contexts/globalStore';
import { tableStorage } from 'contexts/tableStorage';
import { CostCenter, useCostCenterStore } from 'contexts/globalStoreCostCenters';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import useUserDataRolesStore from 'contexts/authStore';
import { checkIfUserCostCenterCreator } from 'utils/roleHelpers';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';

type RowObj = CostCenter & {
  action: string;
};

export default function SearchTableBookings() {
  const textColor = useColorModeValue('navy.700', 'white');
  const navigate = useNavigate();

  const toast = useToast();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const { costCenters } = useCostCenterStore(
    (state: any) => ({ costCenters: state.costCenters })
  );

  const { costCentersMeta } = useCostCenterStore(
    (state: any) => ({ costCentersMeta: state.costCentersMeta })
  );

  const userRoles = useUserDataRolesStore((state) => state.userRoles);
  const isUserCostCenterCreator = useMemo(() => checkIfUserCostCenterCreator(userRoles), [userRoles]);

  const fetchCostCenters = useCostCenterStore((state) => state.fetchCostCenters);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);

  const refreshData = useCallback(() => {
    setLoadingState(true);
    fetchCostCenters(
      undefined,
      pagination.pageIndex + 1,
      pagination.pageSize,
      sorting[0]?.id || null,
      sorting[0]?.desc ? 'desc' : 'asc',
    )
      .then(() => {
        setLoadingState(false);
      })
      .catch((error) => {
        setLoadingState(false);
        console.error('Error fetching Faktura Bookings:', error);
      });
  }, [fetchCostCenters, pagination.pageIndex, pagination.pageSize, setLoadingState, sorting]);

  const deleteCostCenter = useCostCenterStore((state) => state.deleteCostCenter);

  useEffect(() => refreshData(), [refreshData]);

  const [globalFilter, setGlobalFilter] = useState('');
  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="cost-centers-table-header-id"
        >
          id
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`cost-centers-table-cell-id-${info.getValue()}`}>
          #{info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="cost-centers-table-header-name"
        >
          {t('name', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        return (
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            style={{
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              maxWidth: '8ch',
            }}
            data-test-id={`cost-centers-table-cell-name-${id}`}
          >
            {info.getValue()}
          </Text>
        );
      },
    }),

    columnHelper.accessor('isBillable', {
      id: 'isBillable',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="cost-centers-table-header-isBillable"
        >
          {t('isBillable', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;
        return (
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            style={{
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              maxWidth: '8ch',
            }}
            data-test-id={`cost-centers-table-cell-isBillable-${id}`}
          >
            {info.getValue() ? t('yes', { ns: ['labels'] }) : t('no', { ns: ['labels'] })}
          </Text>
        );
      },
    }),

    columnHelper.accessor('description', {
      id: 'description',
      enableSorting: false,
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="cost-centers-table-header-description"
        >
          {t('description', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;
        return (
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            style={{
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              maxWidth: '8ch',
            }}
            data-test-id={`cost-centers-table-cell-description-${id}`}
          >
            {info.getValue()}
          </Text>
        );
      },
    }),

    columnHelper.accessor('action', {
      id: 'action',
      enableSorting: false,
      header: () => (
        <Text
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          paddingEnd={4}
          data-test-id="cost-centers-table-header-action"
        >
          {t('actions', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;
        return (
          <Stack direction="column" spacing="8px" align={'end'}>
            <Flex gap={1}>
              <Button
                cursor="pointer"
                variant="brand"
                onClick={() => navigate(`/cost-centers/edit/${id}`)}
                data-test-id={`cost-centers-table-cell-action-view-${id}`}
              >
                {t('view', { ns: ['actions'] }) + '/' + t('edit', { ns: ['actions'] })}
              </Button>
              {/* I'll keep the delete button in case we decide to get it back */}
              {false && isUserCostCenterCreator ? (
                <Button
                  size="sm"
                  leftIcon={<DeleteIcon />}
                  cursor="pointer"
                  colorScheme="red"
                  w={104}
                  fontSize="xs"
                  data-test-id={`cost-centers-table-cell-action-delete-${id}`}
                  onClick={() => {
                    deleteCostCenter(info.row.original.id).then((res: any) => {
                      toast({
                        title: res.type === 'success' ? t('success', { ns: ['labels'] }) : t('error', { ns: ['labels'] }),
                        description: res.message,
                      });
                    });
                    refreshData();
                  }}
                >
                  {t('delete', { ns: ['actions'] })}
                </Button>
              ) : null}
            </Flex>
          </Stack>
        );
      },
    }),
  ];

  const [data, setData] = useState(() => []);
  useEffect(() => setData(costCenters), [costCenters]);

  const tableName = useMemo(() => 'cost_centers', []);

  useEffect(() => {
    const {
      globalFilter: newGlobalFilter,
      pageSize: newPageSize,
      sorting: newSorting
    } = tableStorage.fetch(tableName)

    if (newGlobalFilter) setGlobalFilter(newGlobalFilter);
    if (newPageSize) setPagination({ ...pagination, pageSize: newPageSize });
    if (newSorting) setSorting(newSorting)
    // Don't add 'pagination' below to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName])

  useEffect(() => {
    tableStorage.save(tableName, { globalFilter, pageSize: pagination.pageSize, sorting })
  }, [globalFilter, pagination.pageSize, sorting, tableName])

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting
    },
    pageCount: costCentersMeta.totalPages,
    rowCount: costCentersMeta.totalItems,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <Flex direction="column" w="100%" overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex
        flexDirection={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        justify={{ sm: 'flex-start', lg: 'space-between' }}
        w="100%"
        px="22px"
        mb="10px"
        minWidth="max-content"
        gap="4"
      >
        <Stack direction={{ sm: 'column', lg: 'row' }} w="100%" gap="20px" justify={'space-between'}>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className="font-lg border-block border p-2 shadow"
            placeholder={t('search', { ns: ['labels'] })}
            style={{ width: '100%' }}
            data-test-id="cost-centers-table-filter-search"
          />
          <Flex
            align={{ sm: 'flex-start', lg: 'flex-start' }}
            justify={{ sm: 'flex-start', lg: 'flex-start' }}
            w={'100%'}
            mb="10px"
            alignItems="center"
            gap="4"
          >
            <IconButton
              aria-label="Reload"
              variant="brand"
              icon={<RepeatIcon />}
              onClick={refreshData}
              data-test-id="cost-centers-table-refresh-button"
            >
              Reload
            </IconButton>
          </Flex>
          {isUserCostCenterCreator ? (
            <Button
              variant="brand"
              minWidth="100px"
              data-test-id="cost-centers-table-create-button"
              onClick={() => navigate('/cost-centers/create')}
            >
              {t('create', { ns: ['actions'] })}
            </Button>
          ) : null}
        </Stack>
        {/* Only display Spacer on larger screens */}
        <Spacer display={{ sm: 'none', lg: 'block' }} />
      </Flex>

      <FakturaTable
        table={table}
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
        dataTestId={`cost-centers-table`}
      />
    </Flex>
  );
}

// A debounced input react component
//TODO DRY
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
    // Don't add onChange to dependencies, it causes infinite rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, debounce]);

  return (
    <SearchBar
      {...props}
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      h="44px"
      w={{ lg: '390px' }}
      borderRadius="16px"
    />
  );
}
