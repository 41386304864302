// Chakra imports
import { Select, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from "react-i18next";
import { useCallback, useEffect } from "react";

export default function ButtonAction() {
	const { i18n } = useTranslation();

	const textColor = useColorModeValue('secondaryGray.900', 'white');

	useEffect(() => {
		let isCurrent = true
		if (isCurrent) {
			//Get the language from local storage
			const savedLanguage = localStorage.getItem('i18nextLng');

			//If there is a language saved in local storage, set it as the default language
			if (savedLanguage) {
				i18n.changeLanguage(savedLanguage);
			}
		}
		return () => {
			isCurrent = false
		}
	}, [i18n])

	const onClickLanguageChange = useCallback((e: any) => {
		const language = e.target.value;

		i18n.changeLanguage(language); //change the language
		localStorage.setItem('i18nextLng', language); //save the language in local storage
	}, [i18n])

	return (
		<Select
			id="lang_selector"
			variant="outline"
			value={i18n.language}
			onChange={onClickLanguageChange}
			color={textColor}
			size='md'
			data-test-id="lang-selector"
		>
			<option value="en">English</option>
			<option value="de">Deutsch</option>
		</Select>
	);
}
