import { Api, PostosProjectsBODYValidationRule, PostosProjectsTasksBODYValidationRule, PostosProjectsUsersBODYValidationRule } from 'utils/postos-api';
import { create } from 'zustand';
import { produce } from 'immer';
import { UseToastOptions } from '@chakra-ui/react';
import { t } from 'i18next';
import { bulkChangeItemsInDBTable } from 'utils/storeHelpers';
// import { ProjectUserRoles } from 'enums/userRoles';
// import { UserData } from './authStore';
import { PAGE_SIZE_DEFAULT } from 'variables/pagination';
import { PaginationMeta, paginationMetaInitialState } from './globalStore';

const api = new Api();

interface RowObj {
  id: number;
  title: string;
  description: string;
  isActive: boolean;
}

interface ToastObj {
  type: UseToastOptions['status'];
  message: string;
}

interface ProjectStore {
  projects: PostosProject[];
  projectsMeta: PaginationMeta,
  project: PostosProject;
  singleProjectUser: ProjectUser[]; // May be several with different roles
  areProjectsLoading: boolean;
  areProjectUsersLoading: boolean;
  projectUsers: ProjectUser[];
  projectTasks: ProjectTaskWithName[];
  projectUsersInDB: PostosProjectsUsersBODYValidationRule[];
  projectTasksInDB: PostosProjectsTasksBODYValidationRule[];
  allTasks: Task[];
  fetchTasks: () => Promise<void>;
  validation: Validation;
  types: Types[];
  hourFormats: HourFormats[];
  currencies: Currencies[];
  languages: Languages[];
  roundings: Roundings[];
  termsOfPayments: TermsOfPayment[];
  setToTrue: (field: any, check: boolean) => void;
  addTaskToProject: (newTask: ProjectTaskWithName) => void;
  deleteTaskFromProject: (taskIndex: any) => void;
  changeTaskInProject: (taskIndex: number, newTask: ProjectTaskWithName) => null | 'error';
  addUserToProject: (newUser: ProjectUser) => void;
  deleteUserFromProject: (userIndex: any) => void;
  changeUserInProject: (index: number, field: any, value: any) => void;
  resetValidation: () => void;
  resetProject: () => void;
  resetProjects: () => void;
  fetchProjects: (
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc',
    all?: boolean,
  ) => Promise<PostosProject[] | RowObj[]>;
  fetchProjectTasks: (projectId: number) => Promise<void>;
  fetchProjectUsers: (projectId?: number, userId?: number, includeFinData?: boolean) => Promise<ProjectUser[]>;
  fetchSingleProjectUser: (userId: number, projectId: number) => Promise<void>;
  addOrEditProjectCall: (userId: number) => Promise<ToastObj>;
  setProjectData: (newData: Partial<ProjectStore['project']>) => void;
  fetchSingleProject: (id: number, isProjectView?: boolean) => Promise<ToastObj>;
  makeUsersUniqueByUserIdAndRole: (users: ProjectUser[]) => ProjectUser[];
  checkIfInvoiceDataIsInProjects: (projectIds: number[]) => Promise<number[]>;
}

interface Validation {
  detailsValidation: boolean;
  companyValidation: boolean;
  invoiceValidation: boolean;
  costCenterValidation: boolean;
  taskValidation: boolean;
  permissionValidation: boolean;
  ratesValidation: boolean;
}

export interface ProjectUser {
  id?: number;
  projectId: number;
  userId: number;
  userRate: number;
  clientRate: number;
  hoursPerMonth: number;
  role: string;
}

export interface Task {
  id?: number;
  name: string;
}

export interface ProjectTask {
  id?: number;
  taskId?: number;
  projectId: number;
}

export interface ProjectTaskWithName extends ProjectTask {
  name: string;
}

export interface Types {
  label: string;
  value: string;
}
export interface HourFormats {
  label: string;
  value: string;
}
export interface Languages {
  label: string;
  value: string;
}
export interface Currencies {
  label: string;
  value: string;
}
export interface Roundings {
  label: string;
  roundingValue: number;
}
export interface TermsOfPayment {
  value: string;
  en: string;
  de: string;
}

export interface PostosProject {
  id?: number;
  title: string;
  description?: string;
  type: string;
  startDate?: string;
  endDate?: string;
  amountOfHours: number;
  hoursPerMonth?: number;
  hourFormat?: string;
  reference?: string;
  vatRate?: number;
  reverseCharge?: boolean;
  language: string;
  currency: string;
  timesheetOnInvoice: boolean;
  costCenterBillable: number;
  costCenterNonBillable?: number;
  termsOfPayment: string;
  rounding?: number;
  roundingType?: string;
  companyId: number;
  contactPersonId?: number;
  createdAt: string;
  createdBy: number;
  editedAt: string;
  editedBy: number;
  isActive: boolean;
  email: string;
  docURL: string;
}

const initialValidationState: Validation = {
  detailsValidation: false,
  companyValidation: false,
  invoiceValidation: false,
  costCenterValidation: false,
  taskValidation: false,
  permissionValidation: false,
  ratesValidation: false
};

const initialState: PostosProject = {
  id: null,
  title: '',
  description: '',
  type: '',
  startDate: null,
  endDate: null,
  amountOfHours: 0,
  hoursPerMonth: 0,
  hourFormat: 'MINUTES',
  vatRate: 0,
  reverseCharge: false,
  language: '',
  currency: '',
  timesheetOnInvoice: false,
  costCenterBillable: null,
  termsOfPayment: '',
  rounding: 0,
  roundingType: null,
  companyId: null,
  contactPersonId: null,
  createdAt: null,
  createdBy: null,
  editedAt: null,
  editedBy: null,
  isActive: true,
  email: null,
  docURL: null
};

const singleProjectUserInitialState: ProjectUser = {
  userId: null,
  projectId: null,
  userRate: 0,
  clientRate: 0,
  hoursPerMonth: 0,
  role: ''
};

const prepareFEProjectForRest = (project: PostosProject): PostosProjectsBODYValidationRule => {
  return {
    title: project.title,
    type: project.type,
    description: project.description,
    amount_of_hours: project.amountOfHours,
    hours_per_month: project.hoursPerMonth,
    hour_format: project.hourFormat,
    currency: project.currency,
    language: project.language,
    rounding: project.rounding,
    cost_center_billable: project.costCenterBillable,
    cost_center_non_billable: project.costCenterNonBillable,
    reference: project.reference,
    vat_rate: project.vatRate,
    end_date: project.endDate,
    start_date: project.startDate,
    reverse_charge: project.reverseCharge,
    terms_of_payment: project.termsOfPayment,
    timesheet_on_invoice: project.timesheetOnInvoice,
    company_id: project.companyId,
    contact_person_id: project.contactPersonId,
    created_at: project.createdAt,
    created_by: project.createdBy,
    edited_at: project.editedAt,
    edited_by: project.editedBy,
    is_active: project.isActive,
    email: project.email,
    doc_url: project.docURL
  };
}

const prepareRestProjectForFE = (project: PostosProjectsBODYValidationRule & { id?: number }): PostosProject => {
  return {
    id: project.id || null,
    title: project.title,
    type: project.type,
    description: project.description,
    amountOfHours: project.amount_of_hours,
    hoursPerMonth: project.hours_per_month,
    hourFormat: project.hour_format,
    currency: project.currency,
    language: project.language,
    costCenterBillable: project.cost_center_billable,
    costCenterNonBillable: project.cost_center_non_billable,
    rounding: project.rounding,
    reference: project.reference,
    vatRate: project.vat_rate,
    endDate: project.end_date,
    startDate: project.start_date,
    reverseCharge: project.reverse_charge,
    timesheetOnInvoice: project.timesheet_on_invoice,
    termsOfPayment: project.terms_of_payment,
    companyId: project.company_id,
    contactPersonId: project.contact_person_id,
    createdAt: project.created_at,
    createdBy: project.created_by,
    editedAt: project.edited_at,
    editedBy: project.edited_by,
    isActive: project.is_active,
    email: project.email,
    docURL: project.doc_url
  };
}

const prepareRestProjectForFERow = (project: PostosProjectsBODYValidationRule & { id?: number }): RowObj => {
  return {
    id: project.id,
    title: project.title,
    description: project.description,
    isActive: project.is_active,
  };
}

const prepareFEProjectUserForRest = (user: ProjectUser): PostosProjectsUsersBODYValidationRule => {
  return {
    user_id: user.userId,
    project_id: user.projectId,
    resource_rate: user.userRate ? Number(user.userRate) : 0,
    client_rate: user.clientRate ? Number(user.clientRate) : 0,
    hours_per_month: user.hoursPerMonth ? Number(user.hoursPerMonth) : 0,
    role: user.role,
  };
}

const prepareRestProjectUserForFE = (user: PostosProjectsUsersBODYValidationRule & { id?: number }): ProjectUser => ({
  id: user.id,
  userId: user.user_id,
  userRate: user.resource_rate,
  clientRate: user.client_rate,
  hoursPerMonth: user.hours_per_month,
  role: user.role,
  projectId: user.project_id,
})

export const useProjectStore = create<ProjectStore>((set, get) => ({
  set: (fn: any) => set(produce(fn)),
  projects: [],
  projectsMeta: paginationMetaInitialState,
  project: { ...initialState },
  singleProjectUser: [{ ...singleProjectUserInitialState }],
  projectUsers: [],
  projectTasks: [],
  projectUsersInDB: [],
  projectTasksInDB: [],
  allTasks: [],
  areProjectsLoading: false,
  areProjectUsersLoading: false,
  resetAllTasks: () => {
    set({
      allTasks: [],
    });
  },
  resetProjects: () => {
    set({
      projects: [],
      projectsMeta: paginationMetaInitialState
    });
  },
  fetchProjects: async (
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc',
    all = false,
  ) => {
    get().resetProjects();
    set({
      areProjectsLoading: true,
    });

    const params: any = {
      page,
      size,
      orderField,
      orderDirection,
      all
    };
    
    try {
      const response: any = await api.postosProjects.postosProjectsSearchTriggerRest(params);

      const projects = response?.data?.data?.body?.items || [];

      let mappedProjects: PostosProject[] | RowObj[];

      if (projects && Array.isArray(projects)) {

        mappedProjects = projects.map((project) => prepareRestProjectForFERow(project));

        set(
          produce((state) => {
            state.projects = mappedProjects;
            state.projectsMeta = response.data?.data?.body?.meta 
          || { totalItems: mappedProjects.length, totalPages: 1 };
          }),
        );
      } else {
        set(
          produce((state) => {
            state.projects = [];
            state.projectsMeta = paginationMetaInitialState;
          }),
        );
      }
      set({
        areProjectsLoading: false,
      });
      return mappedProjects;
    } catch (error) {
      console.error(error);
      set({
        areProjectsLoading: false,
      });
      throw error;
    }
  },
  validation: {
    ...initialValidationState,
  },
  setToTrue: (field: any, check: boolean) => {
    set(
      produce((state) => {
        state.validation[field] = check;
      }),
    );
  },
  resetValidation: () => {
    set({
      validation: { ...initialValidationState },
    });
  },
  resetProject: () => {
    set({
      project: { ...initialState },
      projectUsers: [],
      projectTasks: [],
      projectUsersInDB: [],
      projectTasksInDB: [],
    });
  },
  fetchTasks: async () => {
    try {
      const response: any = await api.postos.postosTasksSearchTriggerRest({});
      set(
        produce((state) => {
          state.allTasks = response.data.data.body;
        }),
      );
    } catch (error) {
      console.log(error);
    }
  },
  createTask: async (task: Task) => {
    try {
      const response: any = await api.postos.postosTasksCreateTriggerRest(task);

      return {
        status: response.status,
        type: 'success',
        task: response.data.data.body,
      }
    } catch (error: any) {
      console.error('Error creating task:', error);
      return {
        type: 'error',
        message:
          t('error', { ns: ['labels'] }) ||
          error.response?.data?.data?.body?.message,
      };
    }
  },
  updateTask: async (task: Task) => {
    try {
      const response: any = await api.postos.postosTasksUpdateTriggerRest(task.id, task);

      return {
        status: response.status,
        type: 'success',
        task: response.data.data.body,
      }
    } catch (error: any) {
      console.error('Error updating task:', error);
      return {
        type: 'error',
        message:
          t('error', { ns: ['labels'] }) ||
          error.response?.data?.data?.body?.message,
      };
    }
  },
  addTaskToProject: (newTask: ProjectTask) =>
    set(
    produce((state) => {
      state.projectTasks.push(newTask);
    })
  ),
  deleteTaskFromProject: (taskIndex: any) =>
    set(
      produce((state) => {
        state.projectTasks.splice(taskIndex, 1);
      })
    ),
  changeTaskInProject: (taskIndex: number, newTask: ProjectTaskWithName) => {
    const allTasksIds = get().allTasks.map((task) => task.id);
    const allTasksNames = get().allTasks.map((task) => task.name);
    // Trying to add a new task with the name that already exists
    if (!allTasksIds.includes(newTask.taskId) && allTasksNames.includes(newTask.name)) {
      return 'error';
    }
    set(
      produce((state) => {
        state.projectTasks[taskIndex] = newTask;
      }),
    )
    return null;
  },
  changeUserInProject: (index: number, field: any, value: any) => {
    set(
      produce((state) => {
        state.projectUsers[index][field] = value;
      }),
    );
  },

  addUserToProject: (newUser: any) =>
    set(
      produce((state) => {
        state.projectUsers.push(newUser);
      }),
    ),
  deleteUserFromProject: (userIndex: any) =>
    set(
      produce((state) => {
        state.projectUsers.splice(userIndex, 1);
      }),
    ),

  types: [
    {
      label: 'HOUR_BUDGET',
      value: 'HOUR_BUDGET',
    },
    {
      label: 'HOUR_BUDGET_WITH_MONTHLY_LIMIT',
      value: 'HOUR_BUDGET_WITH_MONTHLY_LIMIT',
    },
    {
      label: 'MONTHLY_FIXSUM_WITH_HOURS',
      value: 'MONTHLY_FIXSUM_WITH_HOURS',
    },
    { label: 'MONTHLY_DYNAMIC', value: 'MONTHLY_DYNAMIC' },
    { label: 'MONTHLY_FEE', value: 'MONTHLY_FEE' },
  ],
  hourFormats: [
    {
      label: 'minutes',
      value: 'MINUTES',
    },
    {
      label: 'decimals',
      value: 'DECIMALS',
    },
  ],
  termsOfPayments: [
    {
      value: 'P14D',
      en: 'Payable in 14 days',
      de: 'Zahlbar in 14 Tagen',
    },
    {
      value: 'P14D_2P_30D',
      en: 'Payable within 14 calendar days (2% discount) or 30 calendar days without deduction',
      de: 'Zahlbar innerhalb von 14 Kalendertagen (2% Skonto) oder 30 Kalendertagen ohne Abzug',
    },
    {
      value: 'P30D',
      en: 'Payable within 30 days without deduction',
      de: 'Zahlbar innerhalb von 30 Tagen ohne Abzug',
    },
    {
      value: 'P90D',
      en: 'Payable within 90 days without deduction',
      de: 'Zahlbar innerhalb von 90 Tagen ohne Abzug',
    },
    {
      value: 'DOR',
      en: 'Due on receipt',
      de: 'Zahlbar bei Erhalt',
    },
  ],
  currencies: [
    { label: 'Euro', value: 'EUR' },
    { label: 'Great British Pound', value: 'GBP' },
  ],
  languages: [
    { label: 'EN', value: 'EN' },
    { label: 'DE', value: 'DE' },
  ],
  roundings: [
    { label: 'precise', roundingValue: 0 },
    { label: '15', roundingValue: 15 },
    { label: '30', roundingValue: 30 },
    { label: '60', roundingValue: 60 },
  ],
  setProjectData: (newData) => {
    set(
      produce((state) => {
        state.project = { ...state.project, ...newData };
      }),
    )
  },
  fetchProjectTasks: async (projectId: number) => {
    try {
      const response: any = await api.postosProjectsTasks.postosProjectsTasksSearchTriggerRest({
        project_id: projectId
      });

      if (response?.data?.data?.body?.length) {
        const tasksFromRes = response.data.data.body;
        set(
          produce((state) => {
            state.projectTasks = tasksFromRes.map(
              (projectTask: any): ProjectTaskWithName => ({
              id: projectTask.id,
              taskId: projectTask.task_id,
              projectId: projectTask.project_id,
              name: get().allTasks.find((taskFromAll) => taskFromAll.id === projectTask.task_id)?.name || null,
            }));
            state.projectTasksInDB = tasksFromRes;
          }),
        );
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  },
  fetchProjectUsers: async (projectId?: number, userId?: number, includeFinData?: boolean): Promise<ProjectUser[]> => {
    set(produce((state) => {
      state.areProjectUsersLoading = true;
    }));
    try {
      const response: any = await api.postosProjectsUsers.postosProjectsUsersSearchTriggerRest({
        project_id: projectId || null,
        user_id: userId || null,
        includeFinData: includeFinData || false
      });

      if (response?.data?.data?.body?.length) {
        const usersFromDB = response.data.data.body;
        
        const mappedUsers: ProjectUser[] = usersFromDB.map(
          (user: PostosProjectsUsersBODYValidationRule & { id?: number }) =>
            ({ id: user.id, ...prepareRestProjectUserForFE(user) })
        );
        set(
          produce((state) => {
            state.projectUsers = mappedUsers;
            state.projectUsersInDB = usersFromDB;
            state.areProjectUsersLoading = false
          }),
        );
        return mappedUsers;
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      set(produce((state) => {
        state.areProjectUsersLoading = false;
      }));
    }
  },
  fetchSingleProjectUser: async (userId: number, projectId: number) => {
    try {
      const response: any = await api.postosProjectsUsers.postosProjectsUsersSearchTriggerRest({
        project_id: projectId,
        user_id: userId
      });

      if (response?.data?.data?.body?.length) {
        const usersFromDB = response.data.data.body;
        const singleProjectUser: ProjectUser = usersFromDB.map(
          (projectUser: PostosProjectsUsersBODYValidationRule ) => prepareRestProjectUserForFE(projectUser)
        );

        set(
          produce((state) => {
            state.singleProjectUser = singleProjectUser;
          }),
        );
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  },
  fetchSingleProject: async (id: number, isProjectView = true) => {
    get().resetProject();
    try {
      const response: any = await api.postosProjects.postosProjectsFindByIdTriggerRest(id);
      
      if (isProjectView) {
        const project = prepareRestProjectForFE(response.data.data.body);
        set(
          produce((state) => {
            state.project = project;
          }),
        );
        if (!get().project.hourFormat) {
          get().setProjectData({ hourFormat: get().hourFormats[0].value });
        }

        get().fetchTasks();
        get().fetchProjectUsers(project.id, null, true);
        get().fetchProjectTasks(project.id);
      } else {
        set(
          produce((state) => {
            state.projects = [...get().projects, prepareRestProjectForFERow(response.data.data.body)];
          }),
        );
      }
      return {
        status: response.status,
        type: 'success',
        message: t('fetchSuccess', { ns: ['success'] }),
      } as ToastObj;
    } catch (error: any) {
      console.error('Error fetching project:', error);

      return {
        type: 'error',
        message:
          error.response?.data?.message || t('fetchErrorProject', { ns: ['errors'] }),
      };
    }
  },
  addOrEditProjectCall: async (userId: number) => {
    const localProject = structuredClone(get().project);
    const localUsers = structuredClone(get().projectUsers);
    const localTasks = structuredClone(get().projectTasks);

    if (localProject.costCenterNonBillable === 0) {
      localProject.costCenterNonBillable = null;
    }

    if (localProject.contactPersonId === 0) {
      localProject.contactPersonId = null;
    }

    if (!localProject.id && !localProject.createdAt) {
      localProject.createdAt = new Date().toISOString();
      localProject.createdBy = userId;
    }
    localProject.editedAt = new Date().toISOString();
    localProject.editedBy = userId;

    const projectDataForRest = prepareFEProjectForRest(localProject);

    try {
      let res: any
      if (localProject.id) {
        res = await api.postosProjects.postosProjectsUpdateTriggerRest(
          localProject.id, {
          ...projectDataForRest
        })
      } else {
        res = await api.postosProjects.postosProjectsCreateTriggerRest(projectDataForRest);
      }

      if (res?.data?.data?.body) {
        const projectId = res?.data?.data?.body?.id || localProject.id;

        const newUsers = get().makeUsersUniqueByUserIdAndRole(localUsers)
          .map((user: ProjectUser) => {
            if (!user.projectId) {
              user.projectId = projectId;
            }
            return { ...prepareFEProjectUserForRest(user), id: user.id || null };
          });
          
        // Changing project users in corresponding entity
        const projectUsersChangeResponse = await bulkChangeItemsInDBTable(
          structuredClone(get().projectUsersInDB),
          newUsers || [],
          api.postosProjectsUsers.postosProjectsUsersCreateTriggerRest,
          api.postosProjectsUsers.postosProjectsUsersUpdateTriggerRest,
          api.postosProjectsUsers.postosProjectsUsersDeleteTriggerRest,
        )

        if (projectUsersChangeResponse.type !== 'success') {
          await get().fetchSingleProject(projectId);

          return {
            type: 'error',
            message: projectUsersChangeResponse.message
          }
        }

        //Check if task exists in postos_tasks
        const newTasks: PostosProjectsTasksBODYValidationRule[] = await Promise.all(
          localTasks?.map(async (localTask: ProjectTaskWithName) => {
            let taskIdForReq = localTask.taskId || null;

            const foundTask = get().allTasks.find((taskInAll: Task) => taskInAll.name === localTask.name)

            if (!foundTask && localTask.name) {
              try {
                const res: any = await api.postos.postosTasksCreateTriggerRest({ name: localTask.name });
                taskIdForReq = res.data.data.body.id;
              } catch (error) {
                console.error("Error creating task:", error);
              }
            }

            return { id: localTask.id || null, task_id: taskIdForReq, project_id: localProject.id || projectId };
          }));

        // Changing project tasks in corresponding entity
        const projectTasksChangeResponse = await bulkChangeItemsInDBTable(
          structuredClone(get().projectTasksInDB),
          newTasks || [],
          api.postosProjectsTasks.postosProjectsTasksCreateTriggerRest,
          api.postosProjectsTasks.postosProjectsTasksUpdateTriggerRest,
          api.postosProjectsTasks.postosProjectsTasksDeleteTriggerRest,
        )

        if (projectTasksChangeResponse.type !== 'success') {
          await get().fetchSingleProject(projectId);

          return {
            type: 'error',
            message: projectUsersChangeResponse.message
          }
        }

        return {
          type: 'success',
          message: `${t('project', { ns: ['labels'] })} ${localProject.id
              ? t('updated2', { ns: ['labels'] })
              : t('created2', { ns: ['labels'] })
            }`,
          projectId: localProject.id || res.data.data.body.id
        } as ToastObj;
      }
    } catch (error: any) {
      console.error('Error creating project:', error);

      return {
        type: 'error',
        message:
          t('error', { ns: ['labels'] }) ||
          error.response?.data?.data?.body?.message,
      };
    }
  },
  makeUsersUniqueByUserIdAndRole: (users: ProjectUser[]) => {
    const uniqueUsers = new Map<string, ProjectUser>();

    users.forEach(user => {
      const key = `${user.userId}_${user.role}`;
      if (!uniqueUsers.has(key)) {
        uniqueUsers.set(key, user);
      }
    });

    return Array.from(uniqueUsers.values());
  },
  checkIfInvoiceDataIsInProjects: async (projectIds: number[]) => {
    try {
      const response: any = await api.projects.postosProjectCheckIfInvoiceDataExists({project_ids: projectIds});
      
      const projectIdsWithInvoiceData = response?.data?.data?.body?.projectIdsWithInvoiceData;
      
      return projectIdsWithInvoiceData;
    } catch (error: any) {
      console.error('Error by checking project invoice data:', error);

      return {
        type: 'error',
        message:
          error.response?.data?.message || t('fetchErrorProject', { ns: ['errors'] }),
      };
    }
  }
}));
