import { Api, PostosCostCentersBODYValidationRule } from 'utils/postos-api';
import { create } from 'zustand';
import { produce } from 'immer';
import { UseToastOptions } from '@chakra-ui/react';
import { t } from 'i18next';
import { PAGE_SIZE_DEFAULT } from 'variables/pagination';
import { PaginationMeta, paginationMetaInitialState } from './globalStore';

const api = new Api();

interface ToastObj {
  type: UseToastOptions['status'];
  message: string;
  navigateId?: number | null;
}

interface CostCentersStore {
  costCenters: CostCenter[];
  costCentersMeta: PaginationMeta;
  singleCostCenterData: CostCenter;
  fetchCostCenters: (
    name?: string,
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc'
  ) => Promise<ToastObj>;
  fetchSingleCostCenter: (id: number) => Promise<ToastObj>;
  reset: () => void;
  resetCostCenterList: () => void;
  set: (fn: any) => void;
  addOrEditCostCenterCall: (userId: number) => Promise<ToastObj>;
  deleteCostCenter: (id: number) => Promise<ToastObj>;
}

export interface CostCenter {
  id?: number;
  name: string;
  isBillable: boolean;
  description?: string;
  createdAt: string;
  createdBy: number;
  editedAt: string;
  editedBy: number;
}

const initialState: CostCenter = {
  name: '',
  isBillable: true,
  description: '',
  createdAt: null,
  createdBy: null,
  editedAt: null,
  editedBy: null
};

const prepareRestCostCenterForFE = (costCenter: PostosCostCentersBODYValidationRule): CostCenter => ({
  name: costCenter.name,
  description: costCenter.description,
  isBillable: costCenter.is_billable,
  createdAt: costCenter.created_at,
  createdBy: costCenter.created_by,
  editedAt: costCenter.edited_at,
  editedBy: costCenter.edited_by
})

const prepareFECostCenterForRest = (costCenter: CostCenter): PostosCostCentersBODYValidationRule => ({
  name: costCenter.name,
  description: costCenter.description,
  is_billable: costCenter.isBillable,
  created_at: costCenter.createdAt,
  created_by: costCenter.createdBy,
  edited_at: costCenter.editedAt,
  edited_by: costCenter.editedBy
})

export const useCostCenterStore = create<CostCentersStore>((set, get) => ({
  set: (fn: any) => set(produce(fn)),
  costCenters: [],
  costCentersMeta: paginationMetaInitialState,
  singleCostCenterData: { ...initialState },
  reset: () => {
    set({
      singleCostCenterData: { ...initialState },
    });
  },
  resetCostCenterList: () => {
    set({
      costCenters: [],
      costCentersMeta: paginationMetaInitialState
    });
  },
  fetchCostCenters: async (
    name?: string,
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc',
  ) => {
    if (orderField === 'isBillable') orderField = 'is_billable'; // Quick fix, needs some scalable solution
    try {
      const params: any = {
        page,
        size,
        orderField,
        orderDirection,
      };

      if (name) params.name = name;

      const response: any = await api
        .postosCostCenters
        .postosCostCentersSearchTriggerRest(params);

      const localCostCenters = response.data?.data?.body?.items || [];

      const mappedCostCenters: CostCenter[] = localCostCenters.map(
        (costCenter: any) => (
          { id: costCenter.id, ...prepareRestCostCenterForFE(costCenter)}
        )
      );
      
      set(
        produce((state) => {
          state.costCenters = mappedCostCenters;
          state.costCentersMeta = response.data?.data?.body?.meta 
          || { totalItems: mappedCostCenters.length, totalPages: 1 };
        }),
      );
      return {
        type: 'success',
        message: t('fetchCostCenterSuccess', { ns: ['success'] }),
      };
    } catch (error: any) {
      console.error('Error fetching cost centers:', error);

      return {
        type: 'error',
        message: error.response?.data?.data?.body?.message || t('error', { ns: ['labels'] }),
      };
    }
  },
  deleteCostCenter: async (id: number) => {
    try {
      await api.postosCostCenters.postosCostCentersDeleteTriggerRest({ id: id.toString() });
      get().fetchCostCenters();
      return {
        type: 'success',
        message: t('costCenterDeleted', { ns: ['success'] }),
      };
    } catch (error: any) {
      return {
        type: 'error',
        message: error.response.data.data.body.error.detail,
      };
    }
  },
  fetchSingleCostCenter: async (id: number) => {
    get().reset();
    
    try {
      const response: any = await api
        .postosCostCenters
        .postosCostCentersFindByIdTriggerRest(id);
      const singleCostCenter: PostosCostCentersBODYValidationRule = response?.data?.data?.body;

      if (singleCostCenter) {
        set(
          produce((state) => {
            state.singleCostCenterData = { id, ...prepareRestCostCenterForFE(singleCostCenter)};
          }),
        );
        return {
          type: 'success',
          message: t('fetchCostCenterSuccess', { ns: ['success'] }),
        };
      } else {
        set(
          produce((state) => {
            state.singleCostCenterData = { ...initialState };
          }),
        );
        return {
          type: 'error',
          message: response.data?.data?.body?.message || t('error', { ns: ['labels'] }),
        };
      }
    } catch (error: any) {
      console.error(error);
      return {
        type: 'error',
        message: error.response?.data?.data?.body?.message || t('error', { ns: ['labels'] }),
      };
    }
  },
  addOrEditCostCenterCall: async (userId: number) => {
    const localCostCenter = structuredClone(get().singleCostCenterData);
    const { id } = localCostCenter;

    if (!localCostCenter.id && !localCostCenter.createdAt) {
      localCostCenter.createdAt = new Date().toISOString();
      localCostCenter.createdBy = userId;
    }
    localCostCenter.editedAt = new Date().toISOString();
    localCostCenter.editedBy = userId;

    try {
      const res: any = id
        ? await api
          .postosCostCenters
          .postosCostCentersUpdateTriggerRest(
            id, prepareFECostCenterForRest(localCostCenter)
          ) 
        : await api.postosCostCenters.postosCostCentersCreateTriggerRest(
            prepareFECostCenterForRest(localCostCenter)
          );
      return {
        type: 'success',
        message: `Cost center ${id ? 
          t('updated2', { ns: ['labels'] }) : 
          t('created2', { ns: ['labels'] })}`,
        navigateId: res?.data?.data?.body?.id || null,
      };
    } catch (error: any) {
      console.error('Error creating cost center:', error);

      return {
        type: 'error',
        message: error.response?.data?.data?.body?.message || t('error', { ns: ['labels'] }),
      };
    }
  },
}));
