import { Api, PostosTimeBookingsBODYValidationRule } from 'utils/postos-api';
import { create } from 'zustand';
import { produce } from 'immer';
import { UseToastOptions } from '@chakra-ui/react';
import { t } from 'i18next';
import {
  getDateTillFromDateAndDuration,
  getDefaultDateFromSimpleDateString
} from 'utils/dateHelpers';
import { PAGE_SIZE_DEFAULT } from 'variables/pagination';
import { PaginationMeta, paginationMetaInitialState } from './globalStore';

const api = new Api();

interface ToastObj {
  type: UseToastOptions['status'];
  message: string;
  navigateId?: number | null;
  warningMessage?: string;
  data?: Booking;
}

interface BookingsStore {
  projectUser: any | null;
  projectTasks: any[];
  projectUsers: any[];
  allProjects: any[];
  allStatuses: ['DRAFT', 'COMMIT', 'ACCEPTED', 'DECLINED', 'INVOICED', 'PAID', 'OBSOLETE'];
  statusesAdmin: ['DRAFT', 'COMMIT', 'ACCEPTED', 'DECLINED', 'OBSOLETE'];
  statusesUser: ['DRAFT', 'COMMIT', 'OBSOLETE'];
  bookings: Booking[];
  bookingsMeta: PaginationMeta;
  singleBookingData: Booking;
  fetchBookings: (
    from: any,
    till: any,
    projectId: number | null,
    taskId: number | null,
    status: any | null,
    page?: number,
    size?: number,
    orderField?: string,
    orderDirection?: 'asc' | 'desc'
  ) => Promise<ToastObj>;
  fetchSingleBooking: (id: number, isDuplicate?: boolean) => Promise<ToastObj>;
  reset: () => void;
  resetBookingList: () => void;
  set: (fn: any) => void;
  addOrEditBookingCall: (userId: number) => Promise<ToastObj>;
  formatDate: (date: string, type?: string) => string;
  deleteBooking: (id: number) => Promise<ToastObj>;
  changeSingleBookingStatus: (id: number, status: string) => Promise<ToastObj>;
  changeStatuses: (ids: number[], status: any) => Promise<ToastObj>;
}

interface BookingFromApi extends PostosTimeBookingsBODYValidationRule {
  name?: string;
  email?: string;
}

interface Booking {
  id?: number;
  projectId: number;
  userId: number;
  description: string;
  dateFrom: string;
  dateTill: string;
  duration: number;
  status: string;
  taskId: number;
  isBillable: boolean;
  isEntryByTimeframe: boolean;
  internalNote: string;
  createdAt: string;
  createdBy: number;
  editedAt: string;
  editedBy: number;
  name?: string;
  email?: string;
}

const initialState: Booking = {
  projectId: null,
  userId: 0,
  description: '',
  dateFrom: new Date().toISOString().split('T')[0],
  dateTill: '',
  duration: null,
  status: 'DRAFT',
  taskId: 0,
  isBillable: true,
  isEntryByTimeframe: false,
  internalNote: '',
  createdAt: null,
  createdBy: null,
  editedAt: null,
  editedBy: null
};

const prepareRestBookingForFE = (booking: BookingFromApi): Booking => ({
  dateFrom: booking.date_from,
  dateTill: booking.date_till,
  description: booking.description,
  duration: booking.duration,
  isEntryByTimeframe: booking.is_entry_by_time_frame,
  isBillable: booking.is_billable,
  projectId: booking.project_id,
  status: booking.status,
  taskId: booking.task_id,
  userId: booking.user_id,
  internalNote: booking.internal_note,
  createdAt: booking.created_at,
  createdBy: booking.created_by,
  editedAt: booking.edited_at,
  editedBy: booking.edited_by,
  name: booking.name,
  email: booking.email
})

const prepareFEBookingForRest = (booking: Booking): PostosTimeBookingsBODYValidationRule => ({
  date_from: booking.isEntryByTimeframe ?
    booking.dateFrom :
    getDefaultDateFromSimpleDateString(booking.dateFrom),

  date_till: booking.isEntryByTimeframe ?
    booking.dateTill :
    getDateTillFromDateAndDuration(
      getDefaultDateFromSimpleDateString(booking.dateFrom),
      booking.duration
    ),

  description: booking.description,
  duration: booking.duration,
  is_entry_by_time_frame: booking.isEntryByTimeframe,
  is_billable: booking.isBillable,
  project_id: booking.projectId,
  status: booking.status,
  task_id: booking.taskId,
  user_id: booking.userId,
  internal_note: booking.internalNote,
  created_at: booking.createdAt,
  created_by: booking.createdBy,
  edited_at: booking.editedAt,
  edited_by: booking.editedBy
})

export const useBookingStore = create<BookingsStore>((set, get) => ({
  set: (fn: any) => set(produce(fn)),
  projectUser: null,
  projectTasks: [],
  projectUsers: [],
  allProjects: [],
  allStatuses: ['DRAFT', 'COMMIT', 'ACCEPTED', 'DECLINED', 'INVOICED', 'PAID', 'OBSOLETE'],
  statusesAdmin: ['DRAFT', 'COMMIT', 'ACCEPTED', 'DECLINED', 'OBSOLETE'],
  statusesUser: ['DRAFT', 'COMMIT', 'OBSOLETE'],
  bookings: [],
  bookingsMeta: paginationMetaInitialState,
  singleBookingData: { ...initialState },
  reset: () => {
    set({
      singleBookingData: { ...initialState },
    });
  },
  resetBookingList: () => {
    set({
      bookings: [],
      bookingsMeta: paginationMetaInitialState
    });
  },
  fetchBookings: async (
    from: string,
    till: string,
    projectId: number | null,
    taskId: number | null,
    status: string | null,
    page = 1,
    size = PAGE_SIZE_DEFAULT,
    orderField = null,
    orderDirection = 'asc',
  ) => {
    try {
      if (orderField === 'dateFrom') orderField = 'date_from'; // Quick fix, needs some scalable solution
      if (orderField === 'dateTill') orderField = 'date_till'; // Quick fix, needs some scalable solution
      if (orderField === 'user') orderField = 'user_id'; // Quick fix, needs some scalable solution
      if (orderField === 'project') orderField = 'project_id'; // Quick fix, needs some scalable solution
      const params: any = {
        date_from: new Date(from).toISOString(),
        date_till: new Date(till).toISOString(),
        page,
        size,
        orderField,
        orderDirection
      };

      if (projectId) params.project_id = projectId;
      if (status) params.status = status;
      if (taskId) params.task_id = taskId;

      const response: any = await api.postosTimeBookings.postosTimeBookingsSearchTriggerRest(
        params,
      );

      const localBookings = response.data?.data?.body?.items || [];

      const mappedBookings: Booking[] = localBookings.map(
        (booking: any) => (
          { id: booking.id, ...prepareRestBookingForFE(booking)}
        )
      );

      set(
        produce((state) => {
          state.bookings = mappedBookings;
          state.bookingsMeta = response.data?.data?.body?.meta
          || { totalItems: mappedBookings.length, totalPages: 1 };
        }),
      );
      return {
        type: 'success',
        message: t('fetchBookingSuccess', { ns: ['success'] }),
      };
    } catch (error: any) {
      console.error('Error fetching bookings:', error);

      set(
        produce((state) => {
          state.bookings = [];
          state.bookingsMeta = paginationMetaInitialState
        }),
      );

      return {
        type: 'error',
        message: error.response?.data?.data?.body?.message || t('error', { ns: ['labels'] }),
      };
    }
  },
  deleteBooking: async (id: number) => {
    try {
      await api.postosTimeBookings.postosTimeBookingsDeleteTriggerRest(id);
      return {
        type: 'success',
        message: t('projectBookingDeleted', { ns: ['success'] }),
      };
    } catch (error) {
      console.log(error);
      return {
        type: 'error',
        message: t('deleteBookingFailed', { ns: ['success'] }),
      };
    }
  },
  changeSingleBookingStatus: async (id: number, status: string) => {
    try {
      const res: any = await api.postos.postosTimeBookingsStatusChange({
        ids: [id],
        status: status,
      });
      const resMessage = res.data?.data?.body?.message;

      return {
        type: 'success',
        message: `${t('projectBookingStatusChange', { ns: ['success'] }) + t(status, { ns: ['status'] })}
        ${resMessage ? `.
        ${resMessage}` : ''}`,
      };
    } catch (error) {
      console.log(error);
      return {
        type: 'error',
        message: t('errorChangingStatus', { ns: ['error'] }),
      };
    }
  },
  changeStatuses: async (ids: number[], status: any) => {
    try {
      const res: any = await api.postos.postosTimeBookingsStatusChange({
        ids: ids,
        status: status,
      });
      const resMessage = res.data?.data?.body?.message;

      return {
        type: 'success',
        message: `${t('projectBookingStatusesChange', { ns: ['success'] }) + t(status, { ns: ['status'] })}
        ${resMessage ? `.
        ${resMessage}` : ''}`,
      };
    } catch (error) {
      console.log(error);
      return {
        type: 'error',
        message: t('errorChangingStatus', { ns: ['errors'] }),
      };
    }
  },
  fetchSingleBooking: async (id: number, isDuplicate?: boolean) => {
    get().reset();

    try {
      const response: any = await api.postosTimeBookings.postosTimeBookingsFindByIdTriggerRest(id);
      const singleBooking: PostosTimeBookingsBODYValidationRule = response.data.data.body;

      if (singleBooking && !isDuplicate) {
        const singleBookingData = {
          id,
          ...prepareRestBookingForFE(singleBooking),
          dateFrom: singleBooking.is_entry_by_time_frame
            ? get().formatDate(singleBooking.date_from)
            : get().formatDate(singleBooking.date_from, 'date'),
          dateTill: get().formatDate(singleBooking.date_till),
        }
        set(
          produce((state) => {
            state.singleBookingData = singleBookingData;
          }),
        );
        return {
          type: 'success',
          message: t('fetchBookingSuccess', { ns: ['success'] }),
          data: singleBookingData
        };
      } else if (isDuplicate) {
        set(
          produce((state) => {
            state.singleBookingData = {
              ...prepareRestBookingForFE(singleBooking),
              status: initialState.status,
              createdBy: initialState.createdBy,
              editedBy: initialState.editedBy,
              editedAt: initialState.editedAt,
              createdAt: initialState.createdAt,
              dateFrom: initialState.dateFrom,
              dateTill: initialState.dateTill,
              duration: initialState.duration,
            };
          }),
        );

        return {
          type: 'success',
          message: t('fetchBookingSuccess', { ns: ['success'] }),
        };
      } else {
        set(
          produce((state) => {
            state.singleBookingData = { ...initialState };
          }),
        );
        return {
          type: 'error',
          message: response.data?.data?.body?.message || t('error', { ns: ['labels'] }),
        };
      }
    } catch (error: any) {
      console.error(error);
      return {
        type: 'error',
        message: error.response?.data?.data?.body?.message || t('error', { ns: ['labels'] }),
      };
    }
  },
  addOrEditBookingCall: async (userId: number) => {
    if (!get().singleBookingData.userId) {
      return {
        type: 'error',
        message: 'User is required',
      };
    }

    const localBooking = structuredClone(get().singleBookingData);
    const { id } = localBooking;

    if (!id && !localBooking.createdAt) {
      localBooking.createdAt = new Date().toISOString();
      localBooking.createdBy = userId;
    }
    localBooking.editedAt = new Date().toISOString();
    localBooking.editedBy = userId;

    try {
      const res: any = id
        ? await api.postosTimeBookings.postosTimeBookingsUpdateTriggerRest(
          id, prepareFEBookingForRest(localBooking)
        ) : await api.postosTimeBookings.postosTimeBookingsCreateTriggerRest(
          { ...prepareFEBookingForRest(localBooking) }
        );
      return {
        type: 'success',
        message: `${t('booking', { ns: ['labels'] })} ${id ? 
          t('updated2', { ns: ['labels'] }) : 
          t('created2', { ns: ['labels'] })}`,
        navigateId: res?.data?.data?.body?.result?.id || null,
        warningMessage: res?.data?.data?.body?.warningMessage || null,
      };
    } catch (error: any) {
      console.error('Error creating booking:', error);

      return {
        type: 'error',
        message: error.response?.data?.data?.body?.message || t('error', { ns: ['labels'] }),
      };
    }
  },
  formatDate(input: string, type?: string) {
    const date = new Date(input);

    const yyyy = date.getUTCFullYear();
    let MM: string | number = date.getUTCMonth() + 1; // getMonth returns a zero-based value
    let dd: string | number = date.getUTCDate();
    let hh: string | number = date.getUTCHours();
    let mm: string | number = date.getUTCMinutes();

    // Zero-padding for single-digit values
    MM = MM < 10 ? '0' + MM : MM;
    dd = dd < 10 ? '0' + dd : dd;
    hh = hh < 10 ? '0' + hh : hh;
    mm = mm < 10 ? '0' + mm : mm;

    if (type === 'date') {
      return `${yyyy}-${MM}-${dd}`;
    }

    if (type === 'month') {
      return `${yyyy}-${MM}`;
    }

    return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
  },
}));
