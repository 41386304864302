import {
  Badge,
  Box,
  Checkbox,
  IconButton,
  InputGroup,
  SimpleGrid,
  Spacer,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  Icon,
  Stack,
  Select,
  Input,
} from '@chakra-ui/react';
import { MdCircle } from 'react-icons/md';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  RowSelectionState,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PostosProject, useProjectStore } from 'contexts/globalStoreProjects';
import useLoaderStore from 'contexts/globalStore';
import { Link, useNavigate } from 'react-router-dom';
import {
  CheckIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  RepeatIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';
import { useBookingStore } from 'contexts/globalStoreTimeBookings';
import { t } from 'i18next';
import { tableStorage } from 'contexts/tableStorage';
import { convertMinutesToHHmmForInput, zonedEndOfDay } from 'utils/dateHelpers';
import useUserDataRolesStore from 'contexts/authStore';
import BookingStatusBadge from './BookingStatusBadge';
import FakturaTable from 'components/tables/FakturaTable';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';

type RowObj = {
  user: string;
  project: string;
  duration: string;
  dateFrom: string;
  dateTill: string;
  unit: string;
  time: string;
  task: string;
  description: string;
  status: string;
  action: string;
  id: number;
  select: string;
  isEntryByTimeframe: boolean;
  statusChange: string;
};

export default function SearchTableBookings() {
  const textColor = useColorModeValue('navy.700', 'white');
  const navigate = useNavigate();
  const toast = useToast();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const formatDate = useBookingStore((state) => state.formatDate);

  const [initDone, setInitDone] = useState<boolean>(false);

  const { fakturaBookings } = useBookingStore(
    (state: any) => ({ fakturaBookings: state.bookings })
  );
  const { bookingsMeta } = useBookingStore(
    (state: any) => ({ bookingsMeta: state.bookingsMeta })
  );
  const { fakturaTasks } = useProjectStore(
    (state: any) => ({ fakturaTasks: state.allTasks }),
  );
  // const { fakturaUsers } = useFakturaUsersStore(
  //   (state: any) => ({ fakturaUsers: state.users }),
  // );

  const { fakturaProjects } = useProjectStore(
    (state: any) => ({ fakturaProjects: state.projects }),
  );

  const fetchProjects = useProjectStore((state) => state.fetchProjects);
  const projects = useProjectStore((state) => state.projects);
  const deleteFakturaBooking = useBookingStore((state) => state.deleteBooking);
  const changeSingleBookingStatus = useBookingStore(
    (state) => state.changeSingleBookingStatus,
  );
  const changeStatusFakturaBookings = useBookingStore(
    (state) => state.changeStatuses,
  );
  const allStatuses = useBookingStore(
    (state) => state.allStatuses,
  );
  const statusesAdmin = useBookingStore(
    (state) => state.statusesAdmin,
  );
  const hasUserRoleInAnyProject = useUserDataRolesStore((state) => state.hasUserRoleInAnyProject);
  const userProjectsIdsByRole = useUserDataRolesStore((state) => state.userProjectsIdsByRole);
  const allProjectUsersOfTheUser = useUserDataRolesStore((state) => state.allProjectUsersOfTheUser);

  const fetchTasks = useProjectStore((state) => state.fetchTasks);
  // const fetchUsers = useFakturaUsersStore((state) => state.fetchUsers);
  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);
  const fetchBookings = useBookingStore((state) => state.fetchBookings);
  const resetBookingList = useBookingStore((state) => state.resetBookingList);

  const allUserProjectsIds = useUserDataRolesStore((state) => state.allUserProjectsIds);

  const userProjects = useMemo(() => {
    return projects.filter((project: PostosProject) => allUserProjectsIds.includes(project.id));
  }, [allUserProjectsIds, projects]);

  const beginningOfLastMonth = useMemo(() => {
    let date = new Date(); // Current date
    date.setMonth(date.getMonth() - 1); // Subtract one month
    date.setDate(1); // Set to the 1st of the resulting month
    date.setHours(2, 0, 0, 0); // Set time to the start of the day
    return date.toISOString();
  }, []);

  const [startDate, setStartDate] = useState(
    formatDate(beginningOfLastMonth, 'date'),
  );
  const [endDate, setEndDate] = useState(
    formatDate(new Date().toISOString(), 'date'),
  );

  const [projectId, setProjectId] = useState<number | null>(null);
  const [taskId, setTaskId] = useState<number | null>(null);
  const [status, setStatusName] = useState<string>(null);

  const changeBookingsStatus = (
    ids: number[],
    status: any,
  ) => {
    changeStatusFakturaBookings(ids, status)
      .then((res) => {
        refreshData();
        toast({
          title:
            res.type === 'success'
              ? t('success', { ns: ['labels'] })
              : t('error', { ns: ['labels'] }),
          description: res.message,
          status: res.type,
        });
      })
      .catch((error) => {
        console.error('Error changing status:', error);
        toast({
          title: t('error', { ns: ['labels'] }),
          description: error.message,
          status: error.type,
        });
      });
  };

  const changeStatus = (id: number, status: string) => {
    changeSingleBookingStatus(id, status)
      .then((res) => {
        refreshData();
        toast({
          title:
            res.type === 'success'
              ? t('success', { ns: ['labels'] })
              : t('error', { ns: ['labels'] }),
          description: res.message,
          status: res.type,
        });
      })
      .catch((error) => {
        console.error('Error changing status:', error);
        toast({
          title: t('error', { ns: ['labels'] }),
          description: error.message,
          status: error.type,
        });
      });
  };

  const handleStartDateChange = (event: any) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (event: any) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
  };

  const handleProjectIdChange = (event: any) => {
    const newProjectId = event.target.value;
    setProjectId(newProjectId);
  };

  const handleTaskIdChange = (event: any) => {
    const newTaskId = event.target.value;
    setTaskId(newTaskId);
  };
  const handleStatusChange = (event: any) => {
    const newStatus = event.target.value;
    setStatusName(newStatus);
  };

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let isCurrent = true;
    setLoadingState(true);

    const fetchData = async () => {
      const res1 = await fetchTasks();
      const res2 = await fetchProjects(null, null, null, null, true);
      await Promise.all([res1, res2]).then(() => {
        setInitDone(true);
      });
    };

    fetchData().then(() => {
      setLoadingState(false);
    });

    return () => {
      isCurrent = false;
    };
  }, [fetchProjects, fetchTasks, setLoadingState]);

  const [data, setData] = useState(() => []);
  const [taskData, setTaskData] = useState(() => []);
  const [projectData, setProjectData] = useState(() => []);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setData(fakturaBookings);
      // setUsersData(fakturaUsers);
      setTaskData(fakturaTasks);
      setProjectData(fakturaProjects);
    }
    return () => {
      isCurrent = false;
    };
  }, [fakturaBookings, /* fakturaUsers,  */fakturaTasks, fakturaProjects]);


  const [globalFilter, setGlobalFilter] = useState('');
  const columnHelper = createColumnHelper<RowObj>();
  const [newStatus, setStatus] = useState('');

  const columns = [
    columnHelper.accessor('select', {
      id: 'select',
      cell: ({ row }: any) => {
        const id = row.original.id;

        return (
          <>
            <Checkbox
              id="select"
              isChecked={row.getIsSelected() || false}
              onChange={row.getToggleSelectedHandler()}
              colorScheme="brandScheme"
              data-test-id={`bookings-table-checkbox-selected-${id}`}
            />
          </>
        );
      },
    }),

    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="bookings-table-header-id"
        >
          id
        </Text>
      ),
      cell: (info: any) => (
        <Link
          color={textColor}
          data-test-id={`bookings-table-cell-id-${info.getValue()}`}
          to={`/faktura/bookings/edit/${info.getValue()}`}
        >
          <Flex alignItems="center" fontSize="md"
            fontWeight="500"
            color={textColor}
            _hover={{ textDecoration: 'underline' }}
          >
            {<Icon as={EditIcon} mr={1} />}
            #{info.getValue()}
          </Flex>
        </Link>
      ),
    }),
    columnHelper.accessor('user', {
      id: 'user',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="bookings-table-header-user"
        >
          {t('user', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        const userId = info.row.original.userId;
        const user = {
          name: info.row.original.name,
          email: info.row.original.email,
        }

        if (user) {
          const userDisplay = `${user.name} (${user.email})`;

          return (
            <Text
              color={textColor}
              key={userId}
              fontSize="md"
              fontWeight="500"
              data-test-id={`bookings-table-cell-user-${id}`}
            >
              {userDisplay}
            </Text>
          );
        } else {
          return null;
        }
      },
    }),

    columnHelper.accessor('project', {
      id: 'project',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="bookings-table-header-project"
        >
          {t('project', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        const projectId = info.row.original.projectId;
        const project = projectData.find((project) => project.id === projectId);
        const isActive = project?.isActive;

        if (project) {
          return (
            <Flex alignItems={'center'} data-test-id={`bookings-table-cell-project-${id}`}>
              <Link
                color={textColor}
                data-test-id={`bookings-table-cell-id-${info.getValue()}`}
                to={`/projects/edit/${projectId}`}
                target="_blank"
              >
                <Flex alignItems="center" fontSize="md"
                  fontWeight="500"
                  color={textColor}
                  _hover={{ textDecoration: 'underline' }}
                >
                  {project.title}
                  <Icon
                    ml="5px"
                    color={isActive ? 'green.500' : 'gray.500'}
                    as={MdCircle}
                    w="10px"
                    h={'10px'}
                  />
                </Flex>
              </Link>
            </Flex>
          );
        } else {
          return null;
        }
      },
    }),
    columnHelper.accessor('time', {
      id: 'time',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="bookings-table-header-time"
        >
          {t('duration', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;
        const dateFrom = info.row.original.dateFrom;
        const dateTill = info.row.original.dateTill;
        const duration = convertMinutesToHHmmForInput(Number(info.row.original.duration));
        const unit = t('hours', { ns: ['labels'] }); //TODO do not hardcode anymore when we will pass other units

        const dateFromValue = info.row.original.isEntryByTimeframe
          ? new Date(dateFrom).toLocaleString('de-AT', {
            dateStyle: 'medium',
            timeStyle: 'short',
            timeZone: 'UTC',
          })
          : new Date(dateFrom).toLocaleDateString('de-AT');

        const dateTillValue = info.row.original.isEntryByTimeframe
          ? new Date(dateTill).toLocaleString('de-AT', {
            dateStyle: 'medium',
            timeStyle: 'short',
            timeZone: 'UTC',
          })
          : new Date(dateTill).toLocaleDateString('de-AT');

        return (
          <>
            <Box
              color={textColor}
              fontSize="md"
              fontWeight="500"
              data-test-id={`bookings-table-cell-time-${id}`}
              style={{ whiteSpace: 'nowrap' }}
            >
              {`${dateFromValue} -`}
              <Text style={{ whiteSpace: 'nowrap' }}>
                {dateTillValue}
              </Text>
              <Text style={{ whiteSpace: 'nowrap' }}>
                {`(${duration} ${unit})`}
              </Text>
            </Box>
          </>
        );
      },
    }),

    columnHelper.accessor('task', {
      id: 'task',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="bookings-table-header-task"
        >
          {t('task', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        const taskId = info.row.original.taskId;
        const task = taskData.find((task) => Number(task.id) === Number(taskId));
        if (task) {
          return (
            <Badge
              colorScheme="purple"
              fontSize="sm"
              fontWeight="500"
              size="sm"
              key={taskId}
              data-test-id={`bookings-table-cell-task-${id}`}
            >
              {task.name}
            </Badge>
          );
        }
      },
    }),
    columnHelper.accessor('description', {
      id: 'description',
      enableSorting: false,
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="bookings-table-header-description"
        >
          {t('description', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;

        return (
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            style={{
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              maxWidth: '8ch',
            }}
            data-test-id={`bookings-table-cell-description-${id}`}
          >
            {info.getValue()}
          </Text>
        );
      },
    }),

    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="bookings-table-header-status"
        >
          status
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;

        return (<BookingStatusBadge
          status={info.getValue()}
          data-test-id={`bookings-table-cell-status-${id}`}
        />);
      },
    }),
    columnHelper.accessor('statusChange', {
      id: 'statusChange',
      enableSorting: false,
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          paddingEnd={4}
          data-test-id="bookings-table-header-statusChange"
        >
          {t('statusChange', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const projectId = info.row.original.projectId;
        const bookingUserId = info.row.original.userId;
        const status = info.row.original.status;
        const id = info.row.original.id;

        const isUserProjectAdmin = userProjectsIdsByRole.techAdmin.includes(projectId);
        const isUserBookingUser = allProjectUsersOfTheUser.some(projectUser => projectUser.id === bookingUserId);

        return (
          <Stack direction="column" spacing="8px" align={'end'}>
            <Flex gap={1}>
              <Button
                size="sm"
                isDisabled={status !== 'DRAFT' || (!isUserProjectAdmin && !isUserBookingUser)}
                leftIcon={<CheckIcon />}
                cursor="pointer"
                colorScheme="blue"
                fontSize="xs"
                w={104}
                onClick={() => {
                  changeStatus(info.row.original.id, 'COMMIT');
                }}
                data-test-id={`bookings-table-cell-statusChange-accept-${id}`}
              >
                {t('COMMIT', { ns: ['status'] })}
              </Button>
            </Flex>
            <Flex gap={1}>
              <Button
                size="sm"
                isDisabled={status !== 'COMMIT' || !isUserProjectAdmin}
                leftIcon={<CheckIcon />}
                cursor="pointer"
                colorScheme="green"
                fontSize="xs"
                w={104}
                onClick={() => {
                  changeStatus(info.row.original.id, 'ACCEPTED');
                }}
                data-test-id={`bookings-table-cell-statusChange-accept-${id}`}
              >
                {t('approve', { ns: ['actions'] })}
              </Button>
            </Flex>
            <Flex gap={1} alignSelf={'start'}>
              <Button
                size="sm"
                isDisabled={status !== 'COMMIT' ||
                  !isUserProjectAdmin}
                leftIcon={<CloseIcon />}
                cursor="pointer"
                colorScheme="yellow"
                w={104}
                fontSize="xs"
                onClick={() => {
                  changeStatus(info.row.original.id, 'DECLINED');
                }}
                data-test-id={`bookings-table-cell-statusChange-decline-${id}`}
              >
                {t('reject', { ns: ['labels'] })}
              </Button>
            </Flex>
            <Flex gap={1} alignSelf={'start'}>
              <Button
                size="sm"
                isDisabled={
                  !(
                    (status === 'COMMIT' || status === 'DRAFT')
                    && (isUserProjectAdmin || isUserBookingUser)
                  )
                  && !(
                    status === 'DECLINED'
                    && isUserProjectAdmin
                  )
                }
                leftIcon={<SmallCloseIcon />}
                cursor="pointer"
                colorScheme="purple"
                w={104}
                fontSize="xs"
                onClick={() => {
                  changeStatus(info.row.original.id, 'OBSOLETE');
                }}
                data-test-id={`bookings-table-cell-statusChange-obsolete-${id}`}
              >
                {t('OBSOLETE', { ns: ['status'] })}
              </Button>
            </Flex>
          </Stack>
        );
      },
    }),
    columnHelper.accessor('action', {
      id: 'action',
      enableSorting: false,
      header: () => (
        <Text
          justifyContent="space-between"
          align="end"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          paddingEnd={4}
          data-test-id="bookings-table-header-action"
        >
          {t('actions', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const projectId = info.row.original.projectId;
        const bookingUserId = info.row.original.userId;
        const status = info.row.original.status;
        const id = info.row.original.id;

        const isUserProjectAdmin = userProjectsIdsByRole.techAdmin.includes(projectId);
        const isUserBookingUser = allProjectUsersOfTheUser.some(projectUser => projectUser.id === bookingUserId);

        return (
          <Stack direction="column" spacing="8px" align={'end'}>
            <Flex gap={1}>
              <Button
                size="sm"
                isDisabled={
                  (status !== 'COMMIT' && status !== 'DRAFT')
                  || (!isUserProjectAdmin && !isUserBookingUser)
                }
                leftIcon={<EditIcon />}
                cursor="pointer"
                colorScheme="cyan"
                w={104}
                fontSize="xs"
                onClick={() => navigate(`/faktura/bookings/edit/${info.row.original.id}`)}
                data-test-id={`bookings-table-cell-action-edit-${id}`}
              >
                {t('edit', { ns: ['actions'] })}
              </Button>
            </Flex>
            <Flex gap={1} alignSelf={'start'}>
              <Button
                size="sm"
                isDisabled={!isUserProjectAdmin && !isUserBookingUser}
                leftIcon={<CopyIcon />}
                cursor="pointer"
                colorScheme="green"
                color={'black'}
                w={104}
                fontSize="xs"
                onClick={() => navigate(`/faktura/bookings/duplicate/${info.row.original.id}`)}
                data-test-id={`bookings-table-cell-action-duplicate-${id}`}
              >
                {t('duplicate', { ns: ['actions'] })}
              </Button>
            </Flex>
            <Flex gap={1} alignSelf={'start'}>
              <Button
                size="sm"
                isDisabled={status !== 'DRAFT'
                  || !isUserBookingUser}
                leftIcon={<DeleteIcon />}
                cursor="pointer"
                colorScheme="red"
                w={104}
                fontSize="xs"
                onClick={() => {
                  deleteFakturaBooking(info.row.original.id);
                  refreshData();
                }}
                data-test-id={`bookings-table-cell-action-delete-${id}`}
              >
                {t('delete', { ns: ['actions'] })}
              </Button>
            </Flex>
          </Stack>
        );
      },
    }),
  ];

  const tableName = useMemo(() => 'bookings', []);

  useEffect(() => {
    const {
      globalFilter: newGlobalFilter,
      startDate: newStartDate,
      endDate: newEndDate,
      projectId: newProjectId,
      taskId: newTaskId,
      status: newStatus,
      pageSize: newPageSize,
      sorting: newSorting
    } = tableStorage.fetch(tableName)

    if (newGlobalFilter) setGlobalFilter(newGlobalFilter);
    if (newStartDate) setStartDate(newStartDate);
    if (newEndDate) setEndDate(newEndDate);
    if (newProjectId) setProjectId(newProjectId);
    if (newTaskId) setTaskId(newTaskId);
    if (newStatus) setStatusName(newStatus);
    if (newPageSize) setPagination({ ...pagination, pageSize: newPageSize });
    if (newSorting) setSorting(newSorting)
    // Don't add 'pagination' below to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName])

  useEffect(() => {
    tableStorage.save(tableName, {
      globalFilter,
      startDate,
      endDate,
      projectId,
      taskId,
      status,
      pageSize: pagination.pageSize,
      sorting
    })
  }, [globalFilter,
    startDate,
    endDate,
    projectId,
    taskId,
    status,
    tableName,
    pagination.pageSize,
    sorting])

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      rowSelection
    },
    pageCount: bookingsMeta.totalPages,
    rowCount: bookingsMeta.totalItems,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  const selectedBookingsIds = useMemo(() => {
    return table
      .getSelectedRowModel()
      .rows
      .map((row) => Number(row.original.id));
    // Don't change table.getSelectedRowModel().rows to just table
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getSelectedRowModel().rows]);

  const refreshData = useCallback(() => {
    if (startDate && endDate && initDone) {
      setLoadingState(true);
      table.resetRowSelection();
      fetchBookings(
        startDate,
        zonedEndOfDay(new Date(endDate)),
        projectId,
        taskId,
        status,
        pagination.pageIndex + 1,
        pagination.pageSize,
        sorting[0]?.id || null,
        sorting[0]?.desc ? 'desc' : 'asc',
      )
        .then(() => {
          setLoadingState(false);
        })
        .catch((error) => {
          setLoadingState(false);
          console.error('Error fetching Faktura Bookings:', error);
        });
    } else {
      resetBookingList();
    }
  }, [startDate,
    endDate,
    initDone,
    setLoadingState,
    table,
    fetchBookings,
    projectId,
    taskId,
    status,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    resetBookingList]);

  useEffect(() => refreshData(), [refreshData]);

  return (
    <Flex direction="column" w="100%" overflowX={{ sm: 'auto', lg: 'auto' }}>
      {userProjects?.length ? <>
        <Flex
          flexDirection={{ sm: 'column', lg: 'row' }}
          align={{ sm: 'flex-start', lg: 'center' }}
          justify={{ sm: 'flex-start', lg: 'space-between' }}
          w="100%"
          px="22px"
          mb="10px"
          gap="4"
        >
          <SimpleGrid columns={{ base: 1, xl: 5 }} gap="5px" maxWidth={'100%'}>
            <Stack direction="column" gap="20px">
              <Input
                type="date"
                color={textColor}
                size="md"
                value={startDate || ''}
                data-test-id={`bookings-table-filter-startDate`}
                onChange={handleStartDateChange}
              />
            </Stack>
            <Stack direction="column" gap="20px">
              <Input
                type="date"
                color={textColor}
                size="md"
                value={endDate || ''}
                data-test-id={`bookings-table-filter-endDate`}
                onChange={handleEndDateChange}
              />
            </Stack>
            <Stack direction="column" gap="20px">
              <Select
                placeholder={t('projects', { ns: ['labels'] })}
                value={projectId || ''}
                data-test-id={`bookings-table-filter-projectId`}
                onChange={handleProjectIdChange}
              >
                {[...userProjects]?.sort(
                  (a, b) => a.title.localeCompare(b.title)
                ).map((project: any) => (
                  <option key={project.id} value={project.id}>
                    {project.title}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="column" gap="20px">
              <Select
                placeholder={t('task', { ns: ['labels'] })}
                value={taskId || ''}
                data-test-id={`bookings-table-filter-taskId`}
                onChange={handleTaskIdChange}
              >
                {[...fakturaTasks].sort(
                  (a, b) => a.name.localeCompare(b.name)
                ).map((task: any) => (
                  <option key={task.id} value={task.id}>
                    {task.name}
                  </option>
                ))}
              </Select>
            </Stack>
            <Stack direction="column" gap="20px">
              <Select
                placeholder="Status"
                value={status || ''}
                data-test-id={`bookings-table-filter-status`}
                onChange={handleStatusChange}
              >
                {allStatuses.map(status => {
                  if (
                    status !== 'DRAFT'
                    && status !== 'COMMIT'
                    && status !== 'OBSOLETE'
                    && !hasUserRoleInAnyProject.techAdmin
                    && !hasUserRoleInAnyProject.finAdmin
                  ) {
                    return null
                  }

                  if (
                    (status === 'INVOICED' || status === 'PAID')
                    && !hasUserRoleInAnyProject.finAdmin
                  ) {
                    return null
                  }

                  if (
                    status === 'DECLINED'
                    && !hasUserRoleInAnyProject.techAdmin
                  ) {
                    return null
                  }

                  return (
                    <option key={status} value={status}>
                      {t(status, { ns: ['status'] })}
                    </option>
                  )
                })}
              </Select>
            </Stack>
          </SimpleGrid>
          {/* Only display Spacer on larger screens */}
          <Spacer display={{ sm: 'none', lg: 'block' }} />

          {loadingState ? (
            <Spinner />
          ) : (
            <Button
              variant="brand"
              data-test-id={`bookings-table-create-button`}
              onClick={() => navigate('/faktura/bookings/create') as any}
            >
              {t('create', { ns: ['actions'] })}
            </Button>
          )}
        </Flex>
        <Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w="100%"
          px="22px"
          mb="10px"
          minWidth="max-content"
          alignItems="center"
          gap="4"
        >
          <IconButton
            aria-label="Reload"
            variant="brand"
            icon={<RepeatIcon />}
            data-test-id={`bookings-table-refresh-button`}
            onClick={refreshData}
          >
            Reload
          </IconButton>
        </Flex>

        {hasUserRoleInAnyProject.techAdmin ? (<Flex
          align={{ sm: 'flex-start', lg: 'flex-start' }}
          justify={{ sm: 'flex-start', lg: 'flex-start' }}
          w="100%"
          px="22px"
          mb="36px"
          minWidth="max-content"
          alignItems="center"
          gap="4"
        >
          <SimpleGrid
            columns={{ base: 1, xl: 3 }}
            gap="20px"
            mt="5px"
            width="70%"
          >
            <Select
              placeholder={t('statusChange', { ns: ['labels'] })}
              mb="20px"
              data-test-id={`bookings-table-set-status-select`}
              onChange={(e) => setStatus(e.target.value)}
            >
              {statusesAdmin.map(status => (
                <option key={status} value={status}>
                  {t(status, { ns: ['status'] })}
                </option>
              ))}
            </Select>

            <InputGroup>
              <Button
                mb="20px"
                data-test-id={`bookings-table-set-status-submit`}
                onClick={(e) => {
                  changeBookingsStatus(
                    selectedBookingsIds,
                    newStatus
                  );
                }}
              >
                {t('change', { ns: ['actions'] })}
              </Button>
            </InputGroup>
          </SimpleGrid>
        </Flex>) : null}
        <FakturaTable
          table={table}
          loadingState={loadingState}
          pagination={pagination}
          setPagination={setPagination}
          dataTestId={`bookings-table`}
        />
      </> : loadingState
        ? <Spinner ml={5} />
        : <Text
          data-test-id={`bookings-table-no-projects-warning`}
          pl={5}>
          {t('noProjectsNoBookings', { ns: 'labels' })}
        </Text>
      }
    </Flex>
  );
}
